import {
  AppBar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ErrorOutline,
  HelpOutline,
  Menu as MenuIcon,
} from "@material-ui/icons";
import LogoutIcon from "@mui/icons-material/Logout";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import clsx from "clsx";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import packagejson from "../../../../package.json";
import { logout } from "../../../redux/actions/auth";
import CloseSessionDialog from "./CloseSessionDialog";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "0 0 0 0",
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color: theme.palette.primary.menuItem,
  },
  hide: {
    display: "none",
  },
  logout: {
    position: "absolute",
    top: 20,
    right: 20,
    cursor: "pointer",
    color: theme.palette.primary.menuItem,
  },
  consulta: {
    position: "absolute",
    top: 20,
    right: 60,
    cursor: "pointer",
    color: theme.palette.primary.menuItem,
  },
  menu: {
    marginTop: 40,
    marginLeft: 0,
  },
  listItemIcon: {
    marginRight: -20,
  },
}));

const HeaderAppBar = ({ open, handleDrawerToggle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [cerrarSesion, setCerrarSesion] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  const handleLogout = () => {
    dispatch(logout());
    location.pathname = "/";
  };
  const handleOpenDialog = () => {
    setCerrarSesion(true);
  };
  const handleCloseDialog = () => {
    setCerrarSesion(false);
  };

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };
  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logout}>
            <Tooltip title="Cerrar Sesión" aria-label="cerrar sesion">
              <RadioButtonCheckedIcon
                aria-label="cerrar sesion"
                aria-controls="menu-appbar-options"
                aria-haspopup="true"
                onClick={handleOpenDialog}
                color="inherit"
              >
                <LogoutIcon />
              </RadioButtonCheckedIcon>
            </Tooltip>
          </div>
          <>
            <div className={classes.consulta}>
              <Tooltip title="Consulta" aria-label="consulta">
                <HelpOutline onClick={handleOpenMenu} />
              </Tooltip>
            </div>
            <Menu
              id="simple-menu"
              anchorEl={openMenu}
              keepMounted
              open={Boolean(openMenu)}
              onClose={handleCloseMenu}
              className={classes.menu}
            >
              <MenuItem disabled>
                <ListItemIcon size="small" className={classes.listItemIcon}>
                  <ErrorOutline />
                </ListItemIcon>
                <ListItemText primary={`Versión ${packagejson.version}`} />
              </MenuItem>
            </Menu>
          </>
        </Toolbar>
      </AppBar>
      <CloseSessionDialog
        title="Cerrar sesión"
        text="¿Desea cerrar sesión?"
        open={cerrarSesion}
        handleClose={handleCloseDialog}
        handleConfirm={handleLogout}
      />
    </>
  );
};

export default HeaderAppBar;
