import { Button } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "../styles/formStyles";
import GremialistasTable from "./GremialistasTable";

const GremialistasContainer = ({ nextForm }) => {
  const classes = useStyles();
  const gremialistas = useSelector((state) => state.rgrl.postRgrl.gremialistas);

  const handleSubmit = () => {
    nextForm("Contratistas");
  };

  return (
    <>
      <GremialistasTable data={gremialistas} handleSubmit={handleSubmit} />
      <div className={`${classes.buttonContainer} ${classes.buttonAfterTableContainer}`}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Siguiente
        </Button>
      </div>

    </>

  );
};
export default GremialistasContainer;
