import * as Yup from "yup";
import { validarCuilCuit } from "../../../util/cuilcuit";

export const validateNewResponsable = Yup.object({
    cargoEmpresa: Yup.string().nullable().required("Campo obligatorio"),
    apellido: Yup.string()
        .max(50, "Máximo 50 caracteres")
        .required("Campo obligatorio"),
    nombres: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Ingrese sólo letras y espacios")
        .max(50, "Máximo 50 caracteres")
        .optional(),
    cuit: Yup.string()
        .matches(/^\d{11}$/, "El CUIL debe contener 11 dígitos numéricos")
        .required("Campo obligatorio")
        .test({
            name: "cuitValido",
            message: "Debe ingresar un CUIT válido",
            test: function (value) {
                if (value) {
                    return validarCuilCuit(value);
                }
            },
        }),
    tipoDocumento: Yup.string().nullable().required("Campo obligatorio"),
    numeroDocumento: Yup.number()
        .min(10000000, "Mínimo 8 dígitos")
        .max(99999999, "Máximo 8 dígitos")
        .integer("No ingrese caracteres especiales")
        .nullable()
        .typeError("Ingrese sólo números")
        .required("Campo obligatorio"),
    telefono: Yup.string()
        .matches(
            /^[0-9()+-\s]*$/,
            "Solo se permiten números y los caracteres ( ) + - y espacio"
        )
        .max(50, "El número de teléfono debe tener como máximo 50 caracteres")
        .optional(),
    email: Yup.string()
        .max(255, "Máximo 255 caracteres")
        .email("Ingrese una dirección de correo válida")
        .optional(),
    tipoMatricula: Yup.number().nullable().required("Campo obligatorio"),
    nroMatricula: Yup.string()
        .max(50, "Máximo 50 caracteres")
        .matches(/^\d*$/, "Ingrese sólo números")
        .required("Campo obligatorio"),
    horasAsignadas: Yup.number("Sólo se permiten números")
        .max(8760, "Máximo 8760 horas")
        .positive("Ingrese números positivos")
        .integer("No ingrese caracteres especiales")
        .nullable()
        .typeError("Ingrese sólo números")
        .required("Campo obligatorio"),
    titulo: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Ingrese sólo letras y espacios")
        .max(100, "Máximo 100 caracteres")
        .optional(),
    matriculaOtorgadaPor: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, "Ingrese sólo letras y espacios")
        .max(100, "Máximo 100 caracteres")
        .required("Campo obligatorio"),
});