import { Paper, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  clearNuevaObra,
  postCargaNuevaObra,
} from "../../../redux/actions/obra";
import { convertDateToNet } from "../../../util/datetime";
import Breadcrumb from "../../commons/breadcrumb/Breadcrumb";
import HeaderLabel from "../../commons/headerLabel/headerLabel";
import HeaderForm from "../../commons/labels/HeaderForm";
import ActividadADesarrollarContainer from "./actividad/ActividadADesarrollarContainer";
import CaracteristicaContainer from "./caracteristica/CaracteristicaContainer";
import NuevaObraLabel from "./commons/NuevaObraLabel";
import DemolicionContainer from "./demolicion/DemolicionContainer";
import IdentificacionContainer from "./identificacion/IdentificacionContainer";
import ResponsablesContainerObra from "./responsables/ResponsablesContainerObra";
import TipoDeObraContainer from "./tipoDeObra/TipoDeObraContainer";

const useStyles = makeStyles({
  breadcrumbContainer: {
    display: "flex",
    margin: 15,
  },
  headerLabel: {
    margin: 15,
  },
});

const NuevaObraContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const postNuevaObra = useSelector((state) => state.obra.postNuevaObra);

  const [form, setForm] = useState("Identificación");
  const [fromBread, setFromBread] = useState(false);
  const [breadcrumbFields, setBreadcrumbFields] = useState([form]);

  useEffect(() => {
    return () => {
      // Clear al dejar de existir el componente
      dispatch(clearNuevaObra());
    };
  }, []);

  const handleSubmitLastForm = () => {
    let finalPayload = {
      ...postNuevaObra.identificacion,
      ...postNuevaObra.caracteristica,
      fechaFinObra: convertDateToNet(postNuevaObra.identificacion.fechaFinObra),
      fechaInicioObra: convertDateToNet(postNuevaObra.identificacion.fechaInicioObra),
      excavacionCAOtros: postNuevaObra.caracteristica.excavacionCAOtros,
      [`resolucion${postNuevaObra.caracteristica.resolucion.replace(
        "/",
        ""
      )}`]: true,
      superficieAConstruir: parseFloat(
        postNuevaObra.caracteristica.superficieAConstruir.replace(",", ".")
      ),
      ...postNuevaObra.demolicion,
      fechaInicioDemolicion: convertDateToNet(postNuevaObra.demolicion.fechaInicioDemolicion),
      fechaFinDemolicion: convertDateToNet(postNuevaObra.demolicion.fechaFinDemolicion),
      fechaInicioExcavacion: convertDateToNet(postNuevaObra.demolicion.fechaInicioExcavacion),
      fechaFinExcavacion: convertDateToNet(postNuevaObra.demolicion.fechaFinExcavacion),
      fechaInicioExcavacionCA: convertDateToNet(postNuevaObra.demolicion.fechaInicioExcavacionCA),
      fechaFinExcavacionCA: convertDateToNet(postNuevaObra.demolicion.fechaFinExcavacionCA),
      responsablesEmpleador: postNuevaObra.responsables.map(
        (r) => r.idResponsableEmpleador
      ),

    };
    // Resolucion
    delete finalPayload.resolucion;

    // Resultados preguntas
    finalPayload["resultadosAvisoObra"] = [
      ...postNuevaObra.tipoDeObra
        .flat()
        .map((a) => a.itemsFormularioArt)
        .flat()
        .map((e) => ({
          idItemFormularioArt: e.idItemFormularioArt,
          seleccionOpcion: e.seleccionOpcion,
          resultado: parseInt(e.resultado),
        })),
      ...postNuevaObra.actividad.preguntas
        .flat()
        .map((a) => a.itemsFormularioArt)
        .flat()
        .map((e) => ({
          idItemFormularioArt: e.idItemFormularioArt,
          seleccionOpcion: e.seleccionOpcion,
          resultado: parseInt(e.resultado),
        })),
    ];

    dispatch(postCargaNuevaObra(finalPayload)).then((e) => {
      toast.success(`Obra número ${e?.value?.data} enviada correctamente`);
      dispatch(clearNuevaObra());
      navigate("/");
    });
  };

  const handleSetForm = (form) => {
    if (!breadcrumbFields.includes(form)) {
      const auxArray = [...breadcrumbFields, form];
      setBreadcrumbFields(auxArray);
    }
    setForm(form);
  };

  const handleCancel = () => {
    dispatch(clearNuevaObra());
    setBreadcrumbFields(["Identificación"]);
    handleSetForm("Identificación");
  };

  const titles = {
    Identificación: "IDENTIFICACIÓN DEL AVISO DE OBRA",
    Característica: "CARACTERÍSTICA DE LA OBRA",
    "Tipo de Obra": "TIPO DE OBRA",
    Actividad: "ACTIVIDADES A DESARROLLAR",
    Demolición: "DEMOLICIÓN - EXCAVACIÓN",
    Responsables: "RESPONSABLES",
    PrePresentacion: "PRE PRESENTACION",
  };

  return (
    <Paper
      style={{ overflow: "hidden", height: "fit-content", minHeight: 650 }}
    >
      <HeaderForm title={titles[form]} />
      <div className={classes.breadcrumbContainer}>
        <Breadcrumb
          setForm={setForm}
          setFields={setBreadcrumbFields}
          fields={breadcrumbFields}
          setFromBread={() => setFromBread(true)}
          form={form}
        />
      </div>
      {form !== "Identificación" && (
        <div className={classes.headerLabel}>
          <HeaderLabel>
            <NuevaObraLabel identificacion={postNuevaObra.identificacion} />
          </HeaderLabel>
        </div>
      )}

      <div style={{ margin: 20 }}>
        {
          {
            Identificación: (
              <IdentificacionContainer nextForm={handleSetForm} />
            ),
            Característica: <CaracteristicaContainer nextForm={handleSetForm} />,
            "Tipo de Obra": <TipoDeObraContainer nextForm={handleSetForm} />,
            Actividad: (
              <ActividadADesarrollarContainer nextForm={handleSetForm} />
            ),
            Demolición: <DemolicionContainer nextForm={handleSetForm} />,
            Responsables: (
              <ResponsablesContainerObra
                handleSubmitLastForm={handleSubmitLastForm}
                handleCancel={handleCancel}
              />
            ),
          }[form]
        }
      </div>
    </Paper>
  );
};

export default NuevaObraContainer;
