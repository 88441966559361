import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getTiposContratista,
    postCaracteristicaForm,
} from "../../../../redux/actions/obra";
import CircularLoading from "../../../commons/loading/CircularLoading";
import CaracteristicaForm from "./CaracteristicaForm";



const CaracteristicaContainer = ({ nextForm }) => {
    const dispatch = useDispatch();

    const tiposContratista = useSelector((state) => state.obra.tipoContratista);
    const caracteristica = useSelector(
        (state) => state.obra.postNuevaObra.caracteristica
    );

    const initialValues = {
        resolucion: "",
        idTipoContratista: "",
        superficieAConstruir: "",
        numeroPlantas: "",
        observaciones: "",
    };

    const [formModel, setFormModel] = useState();

    useEffect(() => {
        if (tiposContratista.data.length === 0) {
            dispatch(getTiposContratista());
        }
    }, []);

    useEffect(() => {
        if (Object.keys(caracteristica).length > 0) {
            setFormModel(caracteristica);
        } else {
            setFormModel(initialValues);
        }
    }, [caracteristica]);

    const handleSubmit = (values) => {
        dispatch(postCaracteristicaForm(values));
        nextForm("Tipo de Obra");
    };

    if (tiposContratista.loading || !formModel) {
        return <CircularLoading />;
    }
    return (
        <CaracteristicaForm
            initialValues={initialValues}
            formModel={formModel}
            handleSubmit={handleSubmit}
        />
    );
};

export default CaracteristicaContainer;
