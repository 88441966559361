import {
    GET_CARGOS,
    GET_DEPENDENCIAS_EMPLEADOR,
    GET_REPRESENTACION,
    GET_RESPONSABLE,
    GET_RESPONSABLES,
    GET_TIPO_DOCUMENTO,
    GET_TIPO_MATRICULA,
    POST_RESPONSABLES
} from "../actions/empleador";

const INITIAL_STATE = {
    responsable: {
        loading: false,
        error: false,
        data: {},
    },
    responsables: {
        loading: false,
        error: false,
        data: [],
    },
    cargos: {
        loading: false,
        error: false,
        data: [],
    },
    tipoDocumento: {
        loading: false,
        error: false,
        data: [],
    },
    representacion: {
        loading: false,
        error: false,
        data: [],
    },
    tipoMatricula: {
        loading: false,
        error: false,
        data: [],
    },
    postResponsables: {
        loading: false,
        error: false,
        data: [],
    },
    dependenciasEmpleador: {
        loading: false,
        error: false,
        data: [],
    },
}




const empleador = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case `${GET_RESPONSABLE}_PENDING`:
            return {
                ...state,
                responsable: {
                    ...state.responsable,
                    error: false,
                    loading: true,
                },
            };
        case `${GET_RESPONSABLE}_REJECTED`:
            return {
                ...state,
                responsable: {
                    ...state.responsable,
                    error: true,
                    loading: false,
                },
            };
        case `${GET_RESPONSABLE}_FULFILLED`:
            return {
                ...state,
                responsable: {
                    ...state.responsable,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case `${GET_RESPONSABLES}_PENDING`:
            return {
                ...state,
                responsables: {
                    ...state.responsables,
                    error: false,
                    loading: true,
                },
            };
        case `${GET_RESPONSABLES}_REJECTED`:
            return {
                ...state,
                responsables: {
                    ...state.responsables,
                    error: true,
                    loading: false,
                },
            };
        case `${GET_RESPONSABLES}_FULFILLED`:
            return {
                ...state,
                responsables: {
                    ...state.responsables,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };

        case `${GET_CARGOS}_PENDING`:
            return {
                ...state,
                cargos: {
                    ...state.cargos,
                    error: false,
                    loading: true,
                },
            };
        case `${GET_CARGOS}_REJECTED`:
            return {
                ...state,
                cargos: {
                    ...state.cargos,
                    error: true,
                    loading: false,
                },
            };
        case `${GET_CARGOS}_FULFILLED`:
            return {
                ...state,
                cargos: {
                    ...state.cargos,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case `${GET_TIPO_DOCUMENTO}_PENDING`:
            return {
                ...state,
                tipoDocumento: {
                    ...state.tipoDocumento,
                    error: false,
                    loading: true,
                },
            };
        case `${GET_TIPO_DOCUMENTO}_REJECTED`:
            return {
                ...state,
                tipoDocumento: {
                    ...state.tipoDocumento,
                    error: true,
                    loading: false,
                },
            };
        case `${GET_TIPO_DOCUMENTO}_FULFILLED`:
            return {
                ...state,
                tipoDocumento: {
                    ...state.tipoDocumento,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case `${GET_REPRESENTACION}_PENDING`:
            return {
                ...state,
                representacion: {
                    ...state.representacion,
                    error: false,
                    loading: true,
                },
            };
        case `${GET_REPRESENTACION}_REJECTED`:
            return {
                ...state,
                representacion: {
                    ...state.representacion,
                    error: true,
                    loading: false,
                },
            };
        case `${GET_REPRESENTACION}_FULFILLED`:
            return {
                ...state,
                representacion: {
                    ...state.representacion,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };

        case `${GET_TIPO_MATRICULA}_PENDING`:
            return {
                ...state,
                tipoMatricula: {
                    ...state.tipoMatricula,
                    error: false,
                    loading: true,
                },
            };
        case `${GET_TIPO_MATRICULA}_REJECTED`:
            return {
                ...state,
                tipoMatricula: {
                    ...state.tipoMatricula,
                    error: true,
                    loading: false,
                },
            };
        case `${GET_TIPO_MATRICULA}_FULFILLED`:
            return {
                ...state,
                tipoMatricula: {
                    ...state.tipoMatricula,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };

        case `${GET_DEPENDENCIAS_EMPLEADOR}_PENDING`:
            return {
                ...state,
                dependenciasEmpleador: {
                    ...state.dependenciasEmpleador,
                    error: false,
                    loading: true,
                },
            };
        case `${GET_DEPENDENCIAS_EMPLEADOR}_REJECTED`:
            return {
                ...state,
                dependenciasEmpleador: {
                    ...state.dependenciasEmpleador,
                    error: true,
                    loading: false,
                },
            };
        case `${GET_DEPENDENCIAS_EMPLEADOR}_FULFILLED`:
            return {
                ...state,
                dependenciasEmpleador: {
                    ...state.dependenciasEmpleador,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };
        case `${POST_RESPONSABLES}_PENDING`:
            return {
                ...state,
                postResponsables: {
                    ...state.postResponsables,
                    error: false,
                    loading: true,
                },
            };
        case `${POST_RESPONSABLES}_REJECTED`:
            return {
                ...state,
                postResponsables: {
                    ...state.postResponsables,
                    error: true,
                    loading: false,
                },
            };
        case `${POST_RESPONSABLES}_FULFILLED`:
            return {
                ...state,
                postResponsables: {
                    ...state.postResponsables,
                    error: false,
                    loading: false,
                    data: action.payload.data,
                },
            };
        default:
            return state;
    }
}

export default empleador;