import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postDemolicionForm } from "../../../../redux/actions/obra";
import { convertDateTimeToNet } from "../../../../util/datetime";
import CircularLoading from "../../../commons/loading/CircularLoading";
import DemolicionForm from "./DemolicionForm";

const DemolicionContainer = ({ nextForm }) => {

    const dispatch = useDispatch();
    const [formModel, setFormModel] = useState();

    const nuevaObra = useSelector((state) => state.obra.postNuevaObra);


    const initialValues = {
        fechaInicioDemolicion: null,
        fechaFinDemolicion: null,
        fechaInicioExcavacion: null,
        fechaFinExcavacion: null,
        fechaInicioExcavacionCA: null,
        fechaFinExcavacionCA: null,
        excavacionCAOtros: "",
    }

    useEffect(() => {
        if (Object.keys(nuevaObra.demolicion).length > 0) {
            setFormModel({
                demolicion: initialValues,
                ...nuevaObra
            });
        } else {
            setFormModel({
                ...nuevaObra,
                demolicion: initialValues,
            });
        }
    }, [nuevaObra])


    const handleSubmit = (values) => {
        dispatch(postDemolicionForm({
            fechaInicioDemolicion: convertDateTimeToNet(values.demolicion.fechaInicioDemolicion),
            fechaFinDemolicion: convertDateTimeToNet(values.demolicion.fechaFinDemolicion),
            fechaInicioExcavacion: convertDateTimeToNet(values.demolicion.fechaInicioExcavacion),
            fechaFinExcavacion: convertDateTimeToNet(values.demolicion.fechaFinExcavacion),
            fechaInicioExcavacionCA: convertDateTimeToNet(values.demolicion.fechaInicioExcavacionCA),
            fechaFinExcavacionCA: convertDateTimeToNet(values.demolicion.fechaFinExcavacionCA),
            excavacionCAOtros: values.demolicion.excavacionCAOtros,
        }));
        nextForm("Responsables");
    }


    if (!formModel) {
        return <CircularLoading />;
    }

    return (
        <DemolicionForm
            initialValues={initialValues}
            formModel={formModel}
            handleSubmit={handleSubmit}
        />
    )
}


export default DemolicionContainer;