import { Button, Typography, useTheme } from "@material-ui/core";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import React from "react";
import { useStyles } from "../styles/formStyles";

const ImportarNominaOkContainer = ({ handleSubmit }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.loadingContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: `${theme.palette.secondary.light}` }}
          >
            Recibido
          </Typography>
          <TaskAltIcon
            style={{ color: `${theme.palette.secondary.light}` }}
            fontSize="large"
          />
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            style={{ marginTop: 20 }}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </>
  );
};

export default ImportarNominaOkContainer;
