import axios from "axios";

export const GET_TIPOS_CONTRATISTA = "GET_TIPOS_CONTRATISTA";
export const POST_IDENTIFICACION_FORM = "POST_IDENTIFICACION_FORM";
export const POST_CARACTERISTICA_FORM = "POST_CARACTERISTICAS_FORM";
export const POST_DEMOLICION = "POST_DEMOLICION";
export const POST_TIPO_DE_OBRA = "POST_TIPO_DE_OBRA";
export const POST_ACTIVIDAD = "POST_ACTIVIDAD";

export const GET_PREGUNTAS_TIPO_DE_OBRA = "GET_PREGUNTAS_TIPO_DE_OBRA";
export const GET_PREGUNTAS_ACTIVIDAD = "GET_PREGUNTAS_ACTIVIDAD";

export const DELETE_LOCAL_RESPONSABLE = "OBRA_DELETE_LOCAL_RESPONSABLE";
export const ADD_LOCAL_RESPONSABLE = "OBRA_ADD_LOCAL_RESPONSABLE";
export const POST_CARGA_NUEVA_OBRA = "POST_CARGA_NUEVA_OBRA";
export const CLEAR_NUEVA_OBRA = "CLEAR_NUEVA_OBRA";
export const VALIDAR_CARGA_NUEVA_OBRA = "VALIDAR_CARGA_NUEVA_OBRA";
export const GET_ESTABLECIMIENTOS = "GET_ESTABLECIMIENTOS";
export const POST_DEPENDENCIA_EMPLEADOR = "POST_DEPENDENCIA_EMPLEADOR";
export const GET_ACTIVIDADES_EMPLEADOR = "GET_ACTIVIDADES_EMPLEADOR";
export const GET_DATOS_ESTABLECIMIENTOS = "GET_DATOS_ESTABLECIMIENTOS";
export const CLEAR_DATOS_ESTABLECIMIENTOS = "CLEAR_DATOS_ESTABLECIMIENTOS";

export const getTiposContratista = () => (dispatch) => {
  return dispatch({
    type: GET_TIPOS_CONTRATISTA,
    payload: axios
      .get(`avisoObra/tiposContratista`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getActividadesEmpleador = () => (dispatch) => {
  return dispatch({
    type: GET_ACTIVIDADES_EMPLEADOR,
    payload: axios
      .get(`afiliaciones/actividades`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getEstablecimientos = () => (dispatch) => {
  return dispatch({
    type: GET_ESTABLECIMIENTOS,
    payload: axios
      .get(`empleador/dependenciasEmpleador?tipoEstablecimiento=O`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getPreguntasTipoDeObra = () => (dispatch) => {
  return dispatch({
    type: GET_PREGUNTAS_TIPO_DE_OBRA,
    payload: axios
      .get(`avisoObra/itemsTipoObra`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getPreguntasActividad = () => (dispatch) => {
  return dispatch({
    type: GET_PREGUNTAS_ACTIVIDAD,
    payload: axios
      .get(`avisoObra/itemsActividadObra`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postDependenciaEmpleador = (values) => (dispatch) => {
  return dispatch({
    type: POST_DEPENDENCIA_EMPLEADOR,
    payload: axios
      .post(`dependenciaEmpleador/cargarDependenciaEmpleador`, values)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
export const getDatosEstablecimiento = (establecimiento) => (dispatch) => {
  return dispatch({
    type: GET_DATOS_ESTABLECIMIENTOS,
    payload: axios
      .get(
        `dependenciaEmpleador/datosBasicos?idDependenciaEmpleador=${establecimiento}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postIdentificacionForm = (values) => (dispatch) => {
  return dispatch({
    type: POST_IDENTIFICACION_FORM,
    payload: values,
  });
};

export const postCaracteristicaForm = (values) => (dispatch) => {
  return dispatch({
    type: POST_CARACTERISTICA_FORM,
    payload: values,
  });
};

export const postDemolicionForm = (values) => (dispatch) => {
  return dispatch({
    type: POST_DEMOLICION,
    payload: values,
  });
};

export const postTipoDeObraForm = (values) => (dispatch) => {
  return dispatch({
    type: POST_TIPO_DE_OBRA,
    payload: values,
  });
};
export const postActividadForm = (values) => (dispatch) => {
  return dispatch({
    type: POST_ACTIVIDAD,
    payload: values,
  });
};
export const addLocalResponsable = (values) => (dispatch) => {
  return dispatch({
    type: ADD_LOCAL_RESPONSABLE,
    payload: values,
  });
};

export const deleteLocalResponsable = (values) => (dispatch) => {
  return dispatch({
    type: DELETE_LOCAL_RESPONSABLE,
    payload: values,
  });
};

export const postCargaNuevaObra = (values) => (dispatch) => {
  return dispatch({
    type: POST_CARGA_NUEVA_OBRA,
    payload: axios
      .post(`avisoObra/cargarAvisoObra`, values)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const clearNuevaObra = () => (dispatch) => {
  return dispatch({
    type: CLEAR_NUEVA_OBRA,
  });
};

export const clearDatosEstablecimiento = () => (dispatch) => {
  return dispatch({
    type: CLEAR_DATOS_ESTABLECIMIENTOS,
  });
};

export const validarCargaNuevaObra = (values) => (dispatch) => {
  const { idDependenciaEmpleador, fechaInicioObra, fechaFinObra } = values;
  return dispatch({
    type: VALIDAR_CARGA_NUEVA_OBRA,
    payload: axios
      .get(
        `avisoObra/validarDisponibilidadCargaAvisoObra?idDependenciaEmpleador=${idDependenciaEmpleador}&fechaInicio=${fechaInicioObra}&fechaFinEstimada=${fechaFinObra}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
