import axios from "axios";

export const GET_RESPONSABLES = "GET_RESPONSABLES";
export const GET_CARGOS = "GET_CARGOS";
export const GET_TIPO_DOCUMENTO = "GET_TIPO_DOCUMENTO";
export const GET_REPRESENTACION = "GET_REPRESENTACION";
export const GET_TIPO_MATRICULA = "GET_TIPO_MATRICULA";
export const GET_DEPENDENCIAS_EMPLEADOR = "GET_DEPENDENCIAS_EMPLEADOR";
export const POST_RESPONSABLES = "POST_RESPONSABLES";
export const GET_RESPONSABLE = "GET_RESPONSABLE";

export const getResponsables = () => (dispatch) => {
  return dispatch({
    type: GET_RESPONSABLES,
    payload: axios
      .get(`empleador/responsablesEmpleador`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getCargos = () => (dispatch) => {
  return dispatch({
    type: GET_CARGOS,
    payload: axios
      .get(`empleador/cargoEmpresa`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getTipoDocumento = () => (dispatch) => {
  return dispatch({
    type: GET_TIPO_DOCUMENTO,
    payload: axios
      .get(`empleador/tipoDocumento`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getRepresentacion = () => (dispatch) => {
  return dispatch({
    type: GET_REPRESENTACION,
    payload: axios
      .get(`empleador/representacion`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getTipoMatricula = () => (dispatch) => {
  return dispatch({
    type: GET_TIPO_MATRICULA,
    payload: axios
      .get(`empleador/tipoMatricula`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getDependenciasEmpleador = () => (dispatch) => {
  return dispatch({
    type: GET_DEPENDENCIAS_EMPLEADOR,
    payload: axios
      .get(`empleador/dependenciasEmpleador?tipoEstablecimiento=E`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const newResponsable = (values) => (dispatch) => {
  return dispatch({
    type: POST_RESPONSABLES,
    payload: axios
      .post(`empleador/cargarResponsableEmpleador`, values)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getResponsable = (id) => (dispatch) => {
  return dispatch({
    type: GET_RESPONSABLE,
    payload: axios
      .get(`empleador/datosResponsableEmpleador?idResponsableEmpleador=${id}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};
