import { differenceInCalendarYears } from "date-fns";
import * as Yup from "yup";
import { validacionCalle, validacionCodigoPostal, validacionDepartamento, validacionKm, validacionLocalidad, validacionNumeroCalle, validacionPais, validacionPiso, validacionProvincia } from "../../../../commons/yup/ValidacionesLocalidades";

export const datosTrabajadorValidation = Yup.object({
  fechaNacimiento: Yup.date()
    .test("mayorDe18", "Debes ser mayor de 18 años", function (value) {
      const today = new Date();
      const age = differenceInCalendarYears(today, value);
      return age >= 18;
    })
    .typeError("Fecha inválida"),
});

export const direccionTrabajadorValidation = Yup.object({
  piso: validacionPiso,
  departamento: validacionDepartamento,
  pais: validacionPais,
  provincia: validacionProvincia,
  localidad: validacionLocalidad,
  calle: validacionCalle,
  codigoPostal: validacionCodigoPostal,
  entreCalle: validacionCalle,
  entreCalle1: validacionCalle,
});

export const datosNominaValidation = Yup.object({
  fechaIngresoEmpleador: Yup.date()
    .required("Campo obligatorio")
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual"),
  horaDesde: Yup.date().nullable().typeError("Fecha inválida"),
  horaHasta: Yup.date().nullable().typeError("Fecha inválida"),
  fechaIngresoDependencia: Yup.date().max(
    new Date(),
    "Debe ser menor a la fecha actual"
  ),
});

export const datosNominaValidationWithDomestica = Yup.object({
  categoriaDomestica: Yup.string().required("Campo obligatorio"),
  fechaIngresoEmpleador: Yup.date()
    .required("Campo obligatorio")
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual"),
  horaDesde: Yup.date().nullable().typeError("Fecha inválida"),
  horaHasta: Yup.date().nullable().typeError("Fecha inválida"),
  fechaIngresoDependencia: Yup.date()
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual"),
});

export const datosDenunciaValidation = Yup.object({
  cuitOcurrencia: Yup.string()
    .matches(/^\d{11}$/, "El CUIT debe contener 11 dígitos numéricos")
    .required("Campo obligatorio"),
  fechaHoraAccidente: Yup.date()
    .max(new Date(), "Debe ser menor a la fecha actual")
    .typeError("Fecha inválida"),
});

export const lugarOcurrenciaValidation = Yup.object({
  kmOcurrencia: validacionKm,
  numeroOcurrencia: validacionNumeroCalle("kmOcurrencia"),
  pisoOcurrencia: validacionPiso,
  departamentoOcurrencia: validacionDepartamento,
  paisOcurrencia: validacionPais,
  provinciaOcurrencia: validacionProvincia,
  localidadOcurrencia: validacionLocalidad,
  calleOcurrencia: validacionCalle,
  codigoPostalOcurrencia: validacionCodigoPostal,
  entreCalleOcurrencia: validacionCalle,
  entreCalle1Ocurrencia: validacionCalle,
});

export const accidenteTrabajoFormValidation = Yup.object({
  subTipoAccidente: Yup.string().nullable().required("Campo obligatorio"),
});

export const agregarDiagnosticoAccidenteValidation = Yup.object({
  diagnosticoOms: Yup.string().required("Campo obligatorio").nullable(),
  gravedad: Yup.string().required("Campo obligatorio").nullable(),
  naturalezaLesion: Yup.string().required("Campo obligatorio").nullable(),
  zonaAfectada: Yup.string().required("Campo obligatorio").nullable(),
});

export const enfermedadProfesionalValidation = Yup.object({
  tipoEnfermedadProfesional: Yup.string()
    .required("Campo obligatorio")
    .nullable(),
  agenteCausante: Yup.string().required("Campo obligatorio").nullable(),
  agenteCausanteEnfermedadProfesional: Yup.string()
    .required("Campo obligatorio")
    .nullable(),
  tiempoExposicionAgenteCausante: Yup.number().min(
    1,
    "El tiempo de exposición debe ser positivo"
  ),
});

export const agregarDiagnosticoEnfermedadValidation = Yup.object({
  diagnosticoOms: Yup.string().required("Campo obligatorio").nullable(),
  gravedad: Yup.string().required("Campo obligatorio").nullable(),
  fechaInicio: Yup.date()
    .typeError("Fecha inválida")
    .max(new Date(), "Debe ser menor a la fecha actual ")
    .nullable(),
  ambitoDeteccionEnfermedadProfesional: Yup.string()
    .required("Campo obligatorio")
    .nullable(),
  zonaAfectada: Yup.string().required("Campo obligatorio").nullable(),
});

export const agregarDiagnosticoEnfermedadValidationWithFechaExamen = Yup.object(
  {
    diagnosticoOms: Yup.string().required("Campo obligatorio").nullable(),
    gravedad: Yup.string().required("Campo obligatorio").nullable(),
    fechaInicio: Yup.date()
      .typeError("Fecha inválida")
      .max(new Date(), "Debe ser menor a la fecha actual ")
      .nullable(),
    ambitoDeteccionEnfermedadProfesional:
      Yup.string().required("Campo obligatorio"),
    fechaExamen: Yup.date()
      .required("Campo obligatorio")
      .typeError("Fecha inválida")
      .max(new Date(), "Debe ser menor a la fecha actual ")
      .nullable(),
    zonaAfectada: Yup.string().required("Campo obligatorio").nullable(),
  }
);

export const validateRequiredFields = (values, model) => {
  const errors = {};
  const errorMessage = "Campo obligatorio";

  for (var value in values) {
    //valida que el campo exista en los datos de la nomina para validar su obligatoriedad
    if (model[value]) {
      if (model[value].obligatorio && !values[value]) {
        errors[value] = errorMessage;
      }
    }
  }

  return errors;
};
