import { Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    grupoTitulo: {
        margin: 15,
        color: `${theme.palette.primary.main}`,
        borderBottom: `solid 2px ${theme.palette.primary.main}`,
    },
}));
const TitleWithUnderline = ({ title }) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Typography variant="h6" className={classes.grupoTitulo}>
                {title}
            </Typography>
        </Grid>


    );
};

export default TitleWithUnderline;
