import {
  Button,
  Card,
  CardContent,
  Grid,
  InputLabel,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearDatosEstablecimiento,
  getDatosEstablecimiento,
  getEstablecimientos,
  postDependenciaEmpleador,
} from "../../../../../redux/actions/obra";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import { useStyles as FormStyle } from "../../styles/formStyle";
import NewEstablecimientoDialog from "./NewEstablecimientoDialog";

const useStyles = makeStyles({
  flex: {
    display: "flex",
  },
  title: {
    padding: 10,
    fontSize: 18,
  },
  LatLongContainer: {
    padding: 2,
  },
  icon: {
    color: "white",
  },
  textValue: {
    margin: "-5px 0px 0px 20px",
  },
  latitud: {
    paddingBottom: 10,
  },
  longitud: {
    paddingBottom: 10,
  },
  latitudContainer: {
    marginRight: 10,
    textAlign: "center",
  },
  longitudContainer: {
    marginLeft: 10,
    textAlign: "center",
  },
});

const UbicacionContainer = () => {
  const dispatch = useDispatch();
  const formClasses = FormStyle();
  const classes = useStyles();
  const establecimientos = useSelector(
    (state) => state.obra.establecimientos.data
  );
  const datosEstablecimiento = useSelector(
    (state) => state.obra.datosEstablecimiento.data
  );
  const { setFieldValue } = useFormikContext();

  const [openEstablecimientoDialog, setOpenEstablecimientoDialog] =
    useState(false);

  const handleSumbitNewEstablecimiento = (values) => {
    let valuesToPost = {
      ...values,
      tipoEstablecimiento: "O",
      principal: false,
      temporal: true,
      nro: !values.km && parseInt(values.numero),
      altura: values.km && parseFloat(values.numero.replace(",", ".")),
    };

    if (values.km) {
      delete valuesToPost.nro;
    } else {
      delete valuesToPost.altura;
    }

    dispatch(postDependenciaEmpleador(valuesToPost)).then((res) => {
      setOpenEstablecimientoDialog(false);
      dispatch(getEstablecimientos()).then(() => {
        setFieldValue("idDependenciaEmpleador", res.value.data);
        dispatch(getDatosEstablecimiento(res.value.data));
      });
    });
  };

  return (
    <>
      <Grid item xs={12} className={formClasses.paddingContainer}>
        <Typography variant="h6">Ubicación de la Obra</Typography>
      </Grid>
      <Grid item xs={6} className={formClasses.paddingContainer}>
        <Card>
          <InputLabel className={classes.title}>Establecimiento *</InputLabel>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <FormikAutocomplete
                  name="idDependenciaEmpleador"
                  variant="standard"
                  options={establecimientos.filter((e) => e.nombre)}
                  getOptions={() => dispatch(getEstablecimientos())}
                  renderOption={(option) => option.nombre}
                  filterOptions={(option) => option.nombre}
                  callBack={(value) => {
                    if (value) {
                      dispatch(getDatosEstablecimiento(value.id));
                    } else {
                      dispatch(clearDatosEstablecimiento());
                    }
                  }}
                />
              </Grid>
              <Grid item xs={2} className={classes.newReponsableIconAligned}>
                <Tooltip arrow title="Nuevo establecimiento">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setOpenEstablecimientoDialog(true)}
                  >
                    <AddCircleOutline className={classes.icon} />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${classes.flex} ${formClasses.paddingContainer}`}
      >
        <InputLabel>Dirección:</InputLabel>
        <Typography className={classes.textValue}>
          {datosEstablecimiento.domicilio || "Sin dirección"}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        className={`${formClasses.paddingContainer} ${classes.flex} `}
      >
        <InputLabel>Dirección GPS:</InputLabel>
        <div
          className={` ${classes.LatLongContainer} ${classes.textValue} ${classes.flex}`}
        >
          <div className={classes.latitudContainer}>
            <InputLabel className={classes.latitud}>Latitud</InputLabel>
            <Typography variant="body2">
              {datosEstablecimiento.latitud
                ? `${datosEstablecimiento.latitud}°`
                : "Sin latitud"}
            </Typography>
          </div>
          <div className={classes.longitudContainer}>
            <InputLabel className={classes.longitud}>Longitud</InputLabel>
            <Typography variant="body2">
              {datosEstablecimiento.longitud
                ? `${datosEstablecimiento.longitud}°`
                : "Sin longitud"}
            </Typography>
          </div>
        </div>
      </Grid>
      <div>
        <NewEstablecimientoDialog
          open={openEstablecimientoDialog}
          handleClose={() => setOpenEstablecimientoDialog(false)}
          handleSumbit={handleSumbitNewEstablecimiento}
        />
      </div>
    </>
  );
};

export default UbicacionContainer;
