import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    gridContainer: {
        paddingRight: 35,
        paddingLeft: 35,
        paddingTop: 15,
        alignItems: "center"
    },
    radioContainer: {
        marginTop: 10,
        marginLeft: 30,
    },
    paddingContainer: {
        padding: 20,
    },
    formikInput: {
        margin: 20,
        marginTop: 0,
    },
    formikRadio: {
        marginTop: 20,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: 10,
    },
    containerMultiline: {
        marginLeft: 50,
        marginRight: 50,
        marginTop: 10
    },
    tableContainer: {
        paddingBottom: 20
    }

}));
