import moment from "moment";

/**
 * @param {Date} datetime - La fecha que queremos transformar
 *
 * @example
 * Recibimos {2024-02-15T12:20:27.902Z} y devolvemos "2024-02-15T12:20"
 *
 * @returns {string} - La fecha como string en formato YYYY-MM-DDTHH:mm
 */
export function convertDateTimeToNet(datetime) {
  return datetime ? moment(datetime).format("YYYY-MM-DDTHH:mm") : null;
}

/**
 * @param {string} datetime - La fecha que queremos transformar
 *
 * @example
 * Recibimos "2023-03-14T15:00:00" y devolvemos "2023-03-14 15:00"
 *
 * @returns {string} - La fecha como string en formato YYYY-MM-DD HH:mm
 */
export const formatForNetDates = (datetime) => {
  return datetime ? moment(datetime).format("YYYY-MM-DD HH:mm") : "";
};

export function convertDateToNet(date) {
  if (date !== null) {
    return moment(date).utcOffset(0).startOf('day').format("YYYY-MM-DDTHH:mm");
  }
  return null;
}