import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  postIdentificacionForm,
  validarCargaNuevaObra,
} from "../../../../redux/actions/obra";
import { convertDateTimeToNet } from "../../../../util/datetime";
import CircularLoading from "../../../commons/loading/CircularLoading";
import IdentificacionForm from "./IdentificacionForm";

const IdentificacionContainer = ({ nextForm }) => {
  const dispatch = useDispatch();

  const identificacion = useSelector(
    (state) => state.obra.postNuevaObra.identificacion
  );

  const initialValues = {
    denominacionObra: "",
    numeroObraSRT: "",
    fechaInicioObra: null,
    fechaFinObra: null,
    idDependenciaEmpleador: null,
  };

  const [formModel, setFormModel] = useState();

  useEffect(() => {
    if (Object.keys(identificacion).length > 0) {
      setFormModel(identificacion);
    } else {
      setFormModel(initialValues);
    }
  }, [identificacion]);

  const handleSubmit = (values) => {
    dispatch(
      validarCargaNuevaObra({
        ...values,
        fechaFinObra: convertDateTimeToNet(values.fechaFinObra),
        fechaInicioObra: convertDateTimeToNet(values.fechaInicioObra),
      })
    ).then(() => {
      dispatch(postIdentificacionForm(values));
      nextForm("Característica");
    });
  };

  if (!formModel) {
    return <CircularLoading />;
  }
  return (
    <IdentificacionForm
      formModel={formModel}
      handleSubmit={handleSubmit}
      setFormModel={setFormModel}
    />
  );
};

export default IdentificacionContainer;
