import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  addLocalResponsable,
  deleteLocalResponsable
} from "../../../../redux/actions/rgrl";
import ConfirmDialog from "../../../commons/dialog/ConfirmDialog";
import ResponsablesContainer from "../../../commons/responsables/ResponsablesContainer";
import { useStyles } from "../styles/formStyles";

const ResponsablesContainerRGRL = ({ nextForm, handleCancel }) => {
  const listaResponsables = useSelector((state) => state.rgrl.postRgrl.responsables)
  const classes = useStyles();
  const [openCancel, setOpenCancel] = useState();
  const handleSubmit = () => {
    nextForm("Presentación")
  }

  return (
    <>
      <ResponsablesContainer
        handleSubmit={handleSubmit}
        listaResponsables={listaResponsables}
        addLocalResponsable={addLocalResponsable}
        deleteLocalResponsable={deleteLocalResponsable}
      />
      <div className={`${classes.buttonContainer} ${classes.buttonAfterTableContainer}`}>
        <Button color="secondary" onClick={() => setOpenCancel(true)}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={listaResponsables.length <= 0}>
          Siguiente
        </Button>
      </div>
      <ConfirmDialog
        open={openCancel}
        handleClose={() => setOpenCancel(false)}
        handleConfirm={() => {
          setOpenCancel(true)
          handleCancel()
        }}
        title="Cancelar"
        text="¿Está seguro de que desea cancelar? Toda la carga realizada en el formulario no se guardará"
      />
    </>

  )
};

export default ResponsablesContainerRGRL;
