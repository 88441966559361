import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  getResponsable,
  getResponsables,
  newResponsable,
} from "../../../redux/actions/empleador";
import RequeridoLabel from "../labels/RequeridoLabel";
import FechaPresentacionLabel from "../planillas/FechaPresentacionLabel";
import ResponsablesTable from "./ResponsablesTable";
import AddResponsableDialog from "./dialog/AddResponsableDialog";
import NewResponsableDialog from "./dialog/NewResponsableDialog";

const initialValues = {
  idResponsableEmpleador: "",
  cuit: "",
  nombreCompleto: "",
  cargoEmpresa: "",
  nroMatricula: "",
  titulo: "",
  matriculaOtorgadaPor: "",
  propio: false,
};

const ResponsablesContainer = ({
  listaResponsables,
  addLocalResponsable,
  deleteLocalResponsable,
  isRequired = true,
}) => {
  const dispatch = useDispatch();

  const [openAddResponsableDialog, setOpenAddResponsableDialog] =
    useState(false);
  const [openNewResponsableDialog, setOpenNewResponsableDialog] =
    useState(false);
  const [openInNewResponsable, setOpenInNewResponsable] = useState(false);

  useEffect(() => {
    dispatch(getResponsables());
  }, []);

  const handleSubmitAddResponsable = (values) => {
    if (listaResponsables.some((r) => r.cuit === values.cuit)) {
      toast.error("El responsable ya se encuentra agregado.");
      setOpenAddResponsableDialog(false);
      setOpenInNewResponsable(false);
    } else {
      dispatch(getResponsable(values.id))
        .then((res) => {
          let model = {
            ...initialValues,
            ...res?.value?.data,
            cuit: res?.value?.data?.cuit.replaceAll("-", ""),
          };
          dispatch(addLocalResponsable(model));
          setOpenAddResponsableDialog(false);
          setOpenInNewResponsable(false);
        })
        .catch((err) => {
          toast.error(err);
          setOpenAddResponsableDialog(false);
          setOpenInNewResponsable(false);
        });
    }
  };

  const handleSubmitNewResponsable = (values) => {
    dispatch(newResponsable(values)).then((resp) => {
      toast.success("Responsable agregado correctamente");
      dispatch(getResponsables()).then((resp) => {
        setOpenNewResponsableDialog(false);
        setOpenAddResponsableDialog(true);
        setOpenInNewResponsable(true);
      });
    });
  };

  const handleOpenNewResponsable = () => {
    setOpenAddResponsableDialog(false);
    setOpenNewResponsableDialog(true);
  };

  const handleCloseAdd = () => {
    setOpenAddResponsableDialog(false);
  };

  const handleCloseNew = () => {
    setOpenNewResponsableDialog(false);
    setOpenAddResponsableDialog(true);
  };

  const deleteResponsable = (values) => {
    dispatch(deleteLocalResponsable(values.cuit));
  };

  return (
    <>
      <FechaPresentacionLabel />
      {isRequired && !listaResponsables.length && (
        <RequeridoLabel text="Es obligatorio ingresar al menos un responsable" />
      )}
      <ResponsablesTable
        data={listaResponsables}
        setOpenAddResponsableDialog={setOpenAddResponsableDialog}
        deleteResponsable={deleteResponsable}
      />
      <AddResponsableDialog
        open={openAddResponsableDialog}
        handleClose={handleCloseAdd}
        handleSubmit={handleSubmitAddResponsable}
        handleOpenNewResponsable={handleOpenNewResponsable}
        openInNewResponsable={openInNewResponsable}
      />
      <NewResponsableDialog
        open={openNewResponsableDialog}
        handleClose={handleCloseNew}
        handleSumbit={handleSubmitNewResponsable}
      />
    </>
  );
};

export default ResponsablesContainer;
