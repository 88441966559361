import { Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import FormikActions from "../../../commons/formik/FormikActions";
import FormikDatePicker from "../../../commons/formik/FormikDatePicker";
import FormikTextField from "../../../commons/formik/FormikTextField";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import { useStyles } from "../styles/formStyle";
import { identificacionFormValidation } from "../validation/formValidation";
import UbicacionContainer from "./ubicacion/UbicacionContainer";

const IdentificacionForm = ({ handleSubmit, formModel, setFormModel }) => {
  const classes = useStyles();


  return (
    <Formik
      initialValues={formModel}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={identificacionFormValidation}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FechaPresentacionLabel />
            </Grid>
            <Grid item xs={6} className={classes.paddingContainer}>
              <div className={classes.formikInput}>
                <FormikTextField
                  name="denominacionObra"
                  label="Denominación de la Obra*"
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={6} className={classes.paddingContainer}>
              <div className={classes.formikInput}>
                <FormikTextField
                  name="numeroObraSRT"
                  label="Número de Aviso de Obra*"
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={6} className={classes.paddingContainer}>
              <div className={classes.formikInput}>
                <FormikDatePicker
                  name="fechaInicioObra"
                  label="Fecha de Inicio de Actividad*"
                  fullWidth
                />
              </div>
            </Grid>

            <Grid item xs={6} className={classes.paddingContainer}>
              <div className={classes.formikInput}>
                <FormikDatePicker
                  name="fechaFinObra"
                  label="Fecha de Fin Estimada*"
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item container xs={12} className={classes.paddingContainer}>
              <UbicacionContainer
                setFormModel={setFormModel}
                formModel={formModel}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions
              submit="Siguiente"
              disabled={
                !(
                  values.denominacionObra &&
                  values.numeroObraSRT &&
                  values.fechaInicioObra &&
                  values.fechaFinObra
                )
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default IdentificacionForm;
