import { Button, useTheme } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  downloadEstructuraArchivo,
  importarRar,
  saveImportacionIdRar,
} from "../../../../redux/actions/nte";
import PdfDialog from "../../../commons/file/PdfDialog";
import RequeridoLabel from "../../../commons/labels/RequeridoLabel";
import CircularLoading from "../../../commons/loading/CircularLoading";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import DatosEstablecimientoHeaderLabel from "../commons/labels/DatosEstablecimientoHeaderLabel";
import { useStyles } from "../styles/formStyles";
import ImportarNominaDropZone from "./ImportarNominaDropZone";
import ImportarNominaErrorTable from "./ImportarNominaErrorTable";
import ImportarNominaOkContainer from "./ImportarNominaOkContainer";

const ImportarNominasContainer = ({ nextForm }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const visualizarArchivoInstrucciones = useSelector(
    (state) => state.nte.descargarEstructuraArchivo.data?.base64
  );
  const searchedNte = useSelector((state) => state.nte.searchedNte);
  const datosEstablecimiento = useSelector(
    (state) => state.nte.datosEstablecimiento
  );
  const postResponsables = useSelector(
    (state) => state.nte.postNte.responsablesForm
  );

  const [nominasCsv, setNominasCsv] = useState(null);
  const [errorImportarNomina, setErrorImportarNomina] = useState(false);
  const [okImportarNomina, setOkImportarNomina] = useState(false);
  const [openInformacionArchivoImportar, setOpenInformacionArchivoImportar] =
    useState(false);
  const [isEnviandoNominas, setIsEnviandoNominas] = useState(false);
  const [dataErrorImportacion, setDataErrorImportacion] = useState(null);

  const handleViewInstruccionesImportar = () => {
    setOpenInformacionArchivoImportar(true);
    dispatch(downloadEstructuraArchivo());
  };

  const handleUploadNomina = () => {
    setIsEnviandoNominas(true);

    dispatch(
      importarRar({
        idDependenciaEmpleador: searchedNte,
        idResponsableEmpleadorDatos:
          postResponsables?.responsableDeDatos ?? null,
        idResponsableEmpleadorSH:
          postResponsables?.responsableDeSeguridad ?? null,
        archivo: {
          base64: nominasCsv[0].value,
          extension: nominasCsv[0].base64Header,
          nombre: nominasCsv[0].filename,
        },
      })
    )
      .then((data) => {
        if (Array.isArray(data)) {
          // si hay errores, el back nos devuelve el array de filas con sus errores
          setDataErrorImportacion(data);
          setIsEnviandoNominas(false);
          setErrorImportarNomina(true);
        } else if (Number.isInteger(data)) {
          // el back, en caso de ok importacion, nos devuelve el idRAR
          dispatch(saveImportacionIdRar(data));
          setIsEnviandoNominas(false);
          setOkImportarNomina(true);
        } else {
          setIsEnviandoNominas(false);
        }
      })
      .catch((err) => {
        setIsEnviandoNominas(false);
      });
  };
  const handleSubmitForm = () => {
    navigate(`/prevencion/establecimientos/${searchedNte}`, {
      state: {
        ...datosEstablecimiento.data,
        // DetalleEstablecimiento.js necesita el idDependencia
        idDependenciaEmpleador: searchedNte,
      },
    });
  };

  const handleReintentarImportacionNomina = () => {
    setNominasCsv(null);
    setDataErrorImportacion(null);
    setErrorImportarNomina(false);
  };

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <>
      <DatosEstablecimientoHeaderLabel />
      <FechaPresentacionLabel />
      {!isEnviandoNominas ? (
        <>
          {!errorImportarNomina && !okImportarNomina && (
            <>
              <ImportarNominaDropZone
                handleViewInstruccionesImportar={
                  handleViewInstruccionesImportar
                }
                setNominasCsv={setNominasCsv}
                nominasCsv={nominasCsv}
              />
              <div className={classes.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleUploadNomina}
                  disabled={
                    nominasCsv === null ||
                    nominasCsv?.length <= 0
                  }
                >
                  SIGUIENTE
                </Button>
              </div>
            </>
          )}
          {errorImportarNomina && (
            <ImportarNominaErrorTable
              data={dataErrorImportacion}
              handleGoHome={handleGoHome}
              handleReintentarImportacionNomina={
                handleReintentarImportacionNomina
              }
            />
          )}
          {okImportarNomina && (
            <ImportarNominaOkContainer handleSubmit={handleSubmitForm} />
          )}
          <PdfDialog
            open={openInformacionArchivoImportar}
            title="Estructura archivo"
            handleClose={() => setOpenInformacionArchivoImportar(false)}
            controllers={true}
            file={visualizarArchivoInstrucciones}
          />
        </>
      ) : (
        <div className={classes.loadingContainer}>
          <RequeridoLabel text="Revisando validez de archivo nóminas..." />
          <CircularLoading
            style={{
              color: `${theme.palette.tertiary.main}`,
            }}
          />
        </div>
      )}
    </>
  );
};

export default ImportarNominasContainer;
