import axios from "axios";
export const POST_DECRETO_FORM = "POST_DECRETO_FORM";
export const GET_FORMULARIOS_PRESENTADOS = "GET_FORMULARIOS_PRESENTADOS";
export const GET_DATOS_ESTABLECIMIENTOS = "GET_DATOS_ESTABLECIMIENTOS";
export const GET_DECRETOS = "GET_DECRETOS";
export const GET_PREGUNTAS_DECRETO = "GET_PREGUNTAS_DECRETO";
export const POST_PREGUNTAS_DECRETO = "POST_PREGUNTAS_DECRETO";
export const GET_SUSTANCIAS_PLANILLA_A = "GET_SUSTANCIAS_PLANILLA_A";
export const CLEAR_ESTABLECIMIENTO_CARDS = "CLEAR_ESTABLECIMIENTO_CARDS";
export const POST_SEARCHED_RGRL = "POST_SEARCHED_RGRL";
export const CLEAR_SEARCHED_RGRL = "CLEAR_SEARCHED_RGRL";
export const POST_GRUPO_PREGUNTAS = "POST_GRUPO_PREGUNTAS";
export const FORMULARIO_COMPLETO = "FORMULARIO_COMPLETO";
export const CLEAR_POST_RGRL = "CLEAR_POST_RGRL";
export const POST_GRUPO_SUSTANCIAS_A = "POST_GRUPO_SUSTANCIAS_A";
export const GET_SUSTANCIAS_PLANILLA_C = "GET_SUSTANCIAS_PLANILLA_C";
export const POST_GRUPO_SUSTANCIAS_C = "POST_GRUPO_SUSTANCIAS_C";
export const GET_GREMIALISTAS = "GET_GREMIALISTAS";
export const ADD_LOCAL_GREMIALISTA = "ADD_LOCAL_GREMIALISTA";
export const DELETE_LOCAL_GREMIALISTA = "DELETE_LOCAL_GREMIALISTA";
export const ADD_LOCAL_CONTRATISTA = "ADD_LOCAL_CONTRATISTA";
export const DELETE_LOCAL_CONTRATISTA = "DELETE_LOCAL_CONTRATISTA";
export const DELETE_LOCAL_RESPONSABLE = "DELETE_LOCAL_RESPONSABLE";
export const ADD_LOCAL_RESPONSABLE = "ADD_LOCAL_RESPONSABLE";
export const CLEAR_RGRL = "CLEAR_RGRL";
export const POST_ALL_RGRL = "POST_ALL_RGRL";
export const GET_VALIDAR_DISPONIBILIDAD_CARGA_RGRL =
  "GET_VALIDAR_DISPONIBILIDAD_CARGA_RGRL";

export const sendAllRGRLForm = (aux) => (dispatch) => {
  return dispatch({
    type: POST_ALL_RGRL,
    payload: axios
      .post(`rgrl/cargarRGRL`, aux, {
        responseType: "blob",
        headers: {
          Accept: "application/pdf",
        },
      })
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getGremialistas = () => (dispatch) => {
  return dispatch({
    type: GET_GREMIALISTAS,
    payload: axios
      .get(`rgrl/gremialistas`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};



export const getDatosEstablecimiento = (establecimiento) => (dispatch) => {
  return dispatch({
    type: GET_DATOS_ESTABLECIMIENTOS,
    payload: axios
      .get(
        `dependenciaEmpleador/datosBasicos?idDependenciaEmpleador=${establecimiento}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getFormulariosPresentados = (establecimiento) => (dispatch) => {
  return dispatch({
    type: GET_FORMULARIOS_PRESENTADOS,
    payload: axios
      .get(
        `dependenciaEmpleador/formulariosAsociados?idDependenciaEmpleador=${establecimiento}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getValidarDisponibilidadCargaRgrl =
  (idDependenciaEmpleador) => (dispatch) => {
    return dispatch({
      type: GET_VALIDAR_DISPONIBILIDAD_CARGA_RGRL,
      payload: axios
        .get(
          `rgrl/validarDisponibilidadCargaRGRL?idDependenciaEmpleador=${idDependenciaEmpleador}`
        )
        .then((response) => ({ data: response.data }))
        .catch((error) => Promise.reject(error)),
    });
  };

export const getDecretos = (idDependenciaEmpleador) => (dispatch) => {
  return dispatch({
    type: GET_DECRETOS,
    payload: axios
      .get(`rgrl/decretos?idDependenciaEmpleador=${idDependenciaEmpleador}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getPreguntasDecreto = (idDecreto) => (dispatch) => {
  return dispatch({
    type: GET_PREGUNTAS_DECRETO,
    payload: axios
      .get(`rgrl/preguntasDecreto?idTipoPlanillaFormularioArt=${idDecreto}`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getSustanciasPlanillaA = () => (dispatch) => {
  return dispatch({
    type: GET_SUSTANCIAS_PLANILLA_A,
    payload: axios
      .get(`rgrl/planillaA`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getSustanciasPlanillaC = () => (dispatch) => {
  return dispatch({
    type: GET_SUSTANCIAS_PLANILLA_C,
    payload: axios
      .get(`rgrl/planillaC`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};


export const postSearchedRgrl = (establecimiento) => (dispatch) => {
  return dispatch({
    type: POST_SEARCHED_RGRL,
    payload: establecimiento,
  });
};

export const postDecretoForm = (values) => (dispatch) => {
  return dispatch({
    type: POST_DECRETO_FORM,
    payload: values,
  });
};

export const postGrupoPreguntas = (values) => (dispatch) => {
  return dispatch({
    type: POST_GRUPO_PREGUNTAS,
    payload: values,
  });
};

export const formularioCompletado = (type) => (dispatch) => {
  return dispatch({
    type: FORMULARIO_COMPLETO,
    payload: type,
  })
}

export const postPreguntasDecreto = (values) => (dispatch) => {
  return dispatch({
    type: POST_PREGUNTAS_DECRETO,
    payload: values,
  });
};

export const postGrupoSustanciasA = (values) => (dispatch) => {
  return dispatch({
    type: POST_GRUPO_SUSTANCIAS_A,
    payload: values,
  });
};

export const postGrupoSustanciasC = (values) => (dispatch) => {
  return dispatch({
    type: POST_GRUPO_SUSTANCIAS_C,
    payload: values,
  });
};

export const addLocalGremialista = (values) => (dispatch) => {
  return dispatch({
    type: ADD_LOCAL_GREMIALISTA,
    payload: values,
  });
};

export const deleteLocalGremialista = (values) => (dispatch) => {
  return dispatch({
    type: DELETE_LOCAL_GREMIALISTA,
    payload: values,
  });
};

export const addLocalContratista = (values) => (dispatch) => {
  return dispatch({
    type: ADD_LOCAL_CONTRATISTA,
    payload: values,
  });
};

export const deleteLocalContratista = (values) => (dispatch) => {
  return dispatch({
    type: DELETE_LOCAL_CONTRATISTA,
    payload: values,
  });
};



export const addLocalResponsable = (values) => (dispatch) => {
  return dispatch({
    type: ADD_LOCAL_RESPONSABLE,
    payload: values,
  });
};

export const deleteLocalResponsable = (values) => (dispatch) => {
  return dispatch({
    type: DELETE_LOCAL_RESPONSABLE,
    payload: values,
  });
};

export const clearEstablecimientoCards = () => (dispatch) => {
  return dispatch({
    type: CLEAR_ESTABLECIMIENTO_CARDS,
  });
};

export const clearSearchedRgrl = () => (dispatch) => {
  return dispatch({
    type: CLEAR_SEARCHED_RGRL,
  });
};

export const clearPostRgrl = () => (dispatch) => {
  return dispatch({
    type: CLEAR_POST_RGRL,
  });
};

export const clearRgrl = () => (dispatch) => {
  return dispatch({
    type: CLEAR_RGRL,
  });
};
