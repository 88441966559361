import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  formularioCompletado,
  getSustanciasPlanillaC,
  postGrupoSustanciasC,
} from "../../../../redux/actions/rgrl";
import CircularLoading from "../../../commons/loading/CircularLoading";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import PlanillaTable from "../../../commons/planillas/PlanillaTable";
import { useStyles } from "../styles/formStyles";

const PlanillaCContainer = ({ nextForm }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const completed = useSelector(
    (state) => state.rgrl.postRgrl.formulariosCompletados.planillaC
  );
  const sustancias = useSelector((state) => state.rgrl.sustanciasC);
  const sustanciasPost = useSelector((state) => state.rgrl.postRgrl.planillaC);

  const [contador, setContador] = useState(0);
  const [grupoActual, setGrupoActual] = useState(null);

  //numero x grupo establecido
  const preguntasPorGrupo = 10;

  const loading = !grupoActual || sustancias.loading;

  // hace falta redondear porque si la cantidad total de preguntas es impar
  // la division da como resultado un numero decimal
  const totalGrupos = Math.ceil(
    sustancias.data[0]?.itemsFormularioArt.length / preguntasPorGrupo
  );
  const tituloGrupo = sustancias.data[0]?.nombre.toLowerCase();

  useEffect(() => {
    dispatch(getSustanciasPlanillaC());
  }, []);

  useEffect(() => {
    if (sustancias.data?.length && !sustanciasPost.length) {
      setGrupoActual(
        sustancias.data[0].itemsFormularioArt.slice(
          contador * preguntasPorGrupo,
          contador * preguntasPorGrupo + preguntasPorGrupo
        )
      );
    }
  }, [sustancias.data]);

  useEffect(() => {
    if (sustanciasPost.length) {
      if (completed) {
        toast.warn("La planilla C ya ha sido completada.");
        nextForm("Gremialistas");
      } else if (sustanciasPost.length <= contador * preguntasPorGrupo) {
        setGrupoActual(
          sustancias.data[0].itemsFormularioArt.slice(
            contador * preguntasPorGrupo,
            contador * preguntasPorGrupo + preguntasPorGrupo
          )
        );
      } else {
        setGrupoActual(
          sustanciasPost.slice(
            contador * preguntasPorGrupo,
            contador * preguntasPorGrupo + preguntasPorGrupo
          )
        );
      }
    }
  }, [contador]);

  const handleSubmit = (values) => {
    dispatch(postGrupoSustanciasC(values.opciones));
    if (
      contador >=
      Math.ceil(
        sustancias.data[0].itemsFormularioArt.length / preguntasPorGrupo
      ) -
        1
    ) {
      dispatch(formularioCompletado("planillaC"));
      nextForm("Gremialistas");
    } else {
      setContador((contador) => contador + 1);
    }
  };

  const handleGoBack = (values) => {
    setContador((contador) => contador - 1);
    dispatch(postGrupoSustanciasC(values.opciones));
  };

  return (
    <>
      <FechaPresentacionLabel />
      {!loading ? (
        <PlanillaTable
          columnLabel="Sustancia"
          data={grupoActual}
          tituloGrupo={tituloGrupo}
          totalGrupos={totalGrupos}
          contador={contador}
          handleSubmit={handleSubmit}
          handleGoBack={handleGoBack}
          fromRgrl
        />
      ) : (
        <div className={classes.loadingContainer}>
          <CircularLoading />
        </div>
      )}
    </>
  );
};

export default PlanillaCContainer;
