import {
  CLEAR_NTE,
  CLEAR_POST_NTE,
  CLEAR_SEARCHED_NTE,
  DESCARGAR_ESTADO_NOMINA,
  DESCARGAR_ESTRUCTURA_ARCHIVO,
  ENVIAR_NTE_FIRMADO,
  GET_DATOS_ESTABLECIMIENTOS,
  IMPORTAR_CSV_RAR_FULFILLED,
  IMPORTAR_CSV_RAR_PENDING,
  IMPORTAR_CSV_RAR_REJECTED,
  POST_ALL_NTE,
  POST_SEARCHED_NTE,
  SAVE_IMPORTACION_ID_RAR,
  SAVE_REPONSABLES_FORM,
  VALIDAR_DISPONIBILIDAD_NTE,
} from "../actions/nte";

const INITIAL_STATE = {
  searchedNte: null,
  importacionIdRar: null,
  importarCsvRar: {
    loading: false,
    error: false,
    data: {},
  },
  descargarEstructuraArchivo: {
    loading: false,
    error: false,
    data: {},
  },
  descargarEstadoNomina: {
    loading: false,
    error: false,
    data: {},
  },
  validarCarga: {
    loading: false,
    error: false,
    data: {},
    validated: false,
  },
  datosEstablecimiento: {
    loading: false,
    error: false,
    data: {},
  },
  postNteFirmado: {
    loading: false,
    error: false,
    data: {},
  },
  postAllNte: {
    loading: false,
    error: false,
    data: {},
  },
  postNte: {
    responsablesForm: {},
  },
};
const nte = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IMPORTAR_CSV_RAR_PENDING:
      return { ...state, importarCsvRar: { loading: true, error: null } };
    case IMPORTAR_CSV_RAR_FULFILLED:
      return {
        ...state,
        importarCsvRar: { loading: false, data: action.payload },
      };
    case IMPORTAR_CSV_RAR_REJECTED:
      return {
        ...state,
        importarCsvRar: { loading: false, error: action.error },
      };
    case `${DESCARGAR_ESTRUCTURA_ARCHIVO}_PENDING`:
      return {
        ...state,
        descargarEstructuraArchivo: {
          ...state.descargarEstructuraArchivo,
          error: false,
          loading: true,
        },
      };
    case `${DESCARGAR_ESTRUCTURA_ARCHIVO}_REJECTED`:
      return {
        ...state,
        descargarEstructuraArchivo: {
          ...state.descargarEstructuraArchivo,
          error: true,
          loading: false,
        },
      };
    case `${DESCARGAR_ESTRUCTURA_ARCHIVO}_FULFILLED`:
      return {
        ...state,
        descargarEstructuraArchivo: {
          ...state.descargarEstructuraArchivo,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${DESCARGAR_ESTADO_NOMINA}_PENDING`:
      return {
        ...state,
        descargarEstadoNomina: {
          ...state.descargarEstadoNomina,
          error: false,
          loading: true,
        },
      };
    case `${DESCARGAR_ESTADO_NOMINA}_REJECTED`:
      return {
        ...state,
        descargarEstadoNomina: {
          ...state.descargarEstadoNomina,
          error: true,
          loading: false,
        },
      };
    case `${DESCARGAR_ESTADO_NOMINA}_FULFILLED`:
      return {
        ...state,
        descargarEstadoNomina: {
          ...state.descargarEstadoNomina,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${VALIDAR_DISPONIBILIDAD_NTE}_PENDING`:
      return {
        ...state,
        validarCarga: {
          ...state.validarCarga,
          error: false,
          loading: true,
        },
      };
    case `${VALIDAR_DISPONIBILIDAD_NTE}_REJECTED`:
      return {
        ...state,
        validarCarga: {
          ...state.validarCarga,
          error: true,
          loading: false,
          validated: false,
        },
      };
    case `${VALIDAR_DISPONIBILIDAD_NTE}_FULFILLED`:
      return {
        ...state,
        validarCarga: {
          ...state.validarCarga,
          error: false,
          loading: false,
          data: action.payload.data,
          validated: true,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_PENDING`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: true,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_REJECTED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: true,
          loading: false,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_FULFILLED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_ALL_NTE}_PENDING`:
      return {
        ...state,
        postAllNte: {
          ...state.postAllNte,
          error: false,
          loading: true,
        },
      };
    case `${POST_ALL_NTE}_REJECTED`:
      return {
        ...state,
        postAllNte: {
          ...state.postAllNte,
          error: true,
          loading: false,
        },
      };
    case `${POST_ALL_NTE}_FULFILLED`:
      return {
        ...state,
        postAllNte: {
          ...state.postAllNte,
          error: false,
          loading: false,
          data: action.payload,
        },
      };
    case `${ENVIAR_NTE_FIRMADO}_PENDING`:
      return {
        ...state,
        postNteFirmado: {
          ...state.postNteFirmado,
          error: false,
          loading: true,
        },
      };
    case `${ENVIAR_NTE_FIRMADO}_REJECTED`:
      return {
        ...state,
        postNteFirmado: {
          ...state.postNteFirmado,
          error: true,
          loading: false,
        },
      };
    case `${ENVIAR_NTE_FIRMADO}_FULFILLED`:
      return {
        ...state,
        postNteFirmado: {
          ...state.postNteFirmado,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case POST_SEARCHED_NTE:
      return {
        ...state,
        searchedNte: action.payload,
      };
    case CLEAR_SEARCHED_NTE:
      return {
        ...state,
        searchedNte: INITIAL_STATE.searchedNte,
      };
    case SAVE_REPONSABLES_FORM:
      return {
        ...state,
        postNte: {
          ...state.postNte,
          responsablesForm: action.payload,
        },
      };
    case SAVE_IMPORTACION_ID_RAR:
      return {
        ...state,
        importacionIdRar: action.payload,
      };
    case CLEAR_POST_NTE:
      return {
        ...state,
        postNte: INITIAL_STATE.postNte,
      };
    case CLEAR_NTE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default nte;
