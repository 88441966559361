import { Grid } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ListItemText } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import moment from "moment";
import * as React from "react";
import { formatForNetDates } from "../../../../../../../util/datetime";

const SecuenciaCard = ({ data }) => {
  return (
    <div>
      {data.map((secuencia) => (
        <Accordion key={secuencia.nroSecuencia}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Secuencia {secuencia.nroSecuencia}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              direction="row"
              container
            >
              <Grid item xs={3}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Fecha ocurrencia
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.fechaHoraOcurrencia
                        ? moment(
                          formatForNetDates(secuencia.fechaHoraOcurrencia)
                        ).format("DD/MM/YYYY HH:mm")
                        : "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Fecha conocimiento
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.fechaTomaConocimiento
                        ? moment(
                          formatForNetDates(secuencia.fechaTomaConocimiento)
                        ).format("DD/MM/YYYY HH:mm")
                        : "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>


              <Grid item xs={3}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Incapacidad
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.incapacidad
                        ? secuencia.incapacidad
                        : "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>


              <Grid item xs={3}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Fecha rechazo
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.fechaRechazo
                        ? moment(
                          formatForNetDates(secuencia.fechaRechazo)
                        ).format("DD/MM/YYYY HH:mm")
                        : "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={3}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Fecha Estimada Alta
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.fechaEstimadaAlta
                        ? moment(
                          formatForNetDates(secuencia.fechaEstimadaAlta)
                        ).format("DD/MM/YYYY HH:mm")
                        : "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Fecha cese
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.fechaCese
                        ? moment(
                          formatForNetDates(secuencia.fechaCese)
                        ).format("DD/MM/YYYY HH:mm")
                        : "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Motivo Cese
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.motivoCeseILT ?? "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={3} >
                <ListItemText
                  primary={
                    <Typography sx={{ fontSize: "14px" }}>
                      Fecha alta
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ fontSize: "13px", color: "grey" }}>
                      {secuencia?.fechaAlta
                        ? moment(
                          formatForNetDates(secuencia.fechaAlta)
                        ).format("DD/MM/YYYY HH:mm")
                        : "Sin Información"}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SecuenciaCard;
