import {
  ADD_LOCAL_CONTRATISTA,
  ADD_LOCAL_GREMIALISTA,
  ADD_LOCAL_RESPONSABLE,
  CLEAR_ESTABLECIMIENTO_CARDS,
  CLEAR_POST_RGRL,
  CLEAR_RGRL,
  CLEAR_SEARCHED_RGRL,
  DELETE_LOCAL_CONTRATISTA,
  DELETE_LOCAL_GREMIALISTA,
  DELETE_LOCAL_RESPONSABLE,
  FORMULARIO_COMPLETO,
  GET_DATOS_ESTABLECIMIENTOS,
  GET_DECRETOS,
  GET_FORMULARIOS_PRESENTADOS,
  GET_GREMIALISTAS,
  GET_PREGUNTAS_DECRETO,
  GET_SUSTANCIAS_PLANILLA_A,
  GET_SUSTANCIAS_PLANILLA_C,
  GET_VALIDAR_DISPONIBILIDAD_CARGA_RGRL,
  POST_ALL_RGRL,
  POST_DECRETO_FORM,
  POST_GRUPO_PREGUNTAS,
  POST_GRUPO_SUSTANCIAS_A,
  POST_GRUPO_SUSTANCIAS_C,
  POST_PREGUNTAS_DECRETO,
  POST_SEARCHED_RGRL,
} from "../actions/rgrl";

const INITIAL_STATE = {
  searchedRgrl: null,
  postAllRgrl: {
    loading: false,
    error: false,
    data: {},
  },
  gremialistas: {
    loading: false,
    error: false,
    data: [],
  },
  cargos: {
    loading: false,
    error: false,
    data: [],
  },
  tipoDocumento: {
    loading: false,
    error: false,
    data: [],
  },
  representacion: {
    loading: false,
    error: false,
    data: [],
  },
  tipoMatricula: {
    loading: false,
    error: false,
    data: [],
  },
  postResponsables: {
    loading: false,
    error: false,
    data: [],
  },
  dependenciasEmpleador: {
    loading: false,
    error: false,
    data: [],
  },
  datosEstablecimiento: {
    loading: false,
    error: false,
    data: {},
  },
  formulariosPresentados: {
    loading: false,
    error: false,
    data: [],
  },
  validarDisponibilidadCargaRgrl: {
    loading: false,
    error: false,
    data: {},
  },
  decretos: {
    loading: false,
    error: false,
    data: {},
  },
  preguntas: {
    loading: false,
    error: false,
    data: {},
  },
  sustanciasA: {
    loading: false,
    error: false,
    data: {},
  },
  sustanciasC: {
    loading: false,
    error: false,
    data: {},
  },
  postRgrl: {
    decretoForm: {},
    preguntasDecreto: { preguntas: [] },
    planillaA: [],
    planillaC: [],
    gremialistas: [],
    contratistas: [],
    responsables: [],
    formulariosCompletados: {
      preguntasDecreto: false,
      planillaA: false,
      planillaC: false,
    }
  },
};

const rgrl = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${POST_ALL_RGRL}_PENDING`:
      return {
        ...state,
        postAllRgrl: {
          ...state.postAllRgrl,
          error: false,
          loading: true,
        },
      };
    case `${POST_ALL_RGRL}_REJECTED`:
      return {
        ...state,
        postAllRgrl: {
          ...state.postAllRgrl,
          error: true,
          loading: false,
        },
      };
    case `${POST_ALL_RGRL}_FULFILLED`:
      return {
        ...state,
        postAllRgrl: {
          ...state.postAllRgrl,
          error: false,
          loading: false,
          data: action.payload,
        },
      };
    case `${GET_GREMIALISTAS}_PENDING`:
      return {
        ...state,
        gremialistas: {
          ...state.gremialistas,
          error: false,
          loading: true,
        },
      };
    case `${GET_GREMIALISTAS}_REJECTED`:
      return {
        ...state,
        gremialistas: {
          ...state.gremialistas,
          error: true,
          loading: false,
        },
      };
    case `${GET_GREMIALISTAS}_FULFILLED`:
      return {
        ...state,
        gremialistas: {
          ...state.gremialistas,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_FORMULARIOS_PRESENTADOS}_PENDING`:
      return {
        ...state,
        formulariosPresentados: {
          ...state.formulariosPresentados,
          error: false,
          loading: true,
        },
      };
    case `${GET_FORMULARIOS_PRESENTADOS}_REJECTED`:
      return {
        ...state,
        formulariosPresentados: {
          ...state.formulariosPresentados,
          error: true,
          loading: false,
        },
      };
    case `${GET_FORMULARIOS_PRESENTADOS}_FULFILLED`:
      return {
        ...state,
        formulariosPresentados: {
          ...state.formulariosPresentados,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_DECRETOS}_PENDING`:
      return {
        ...state,
        decretos: {
          ...state.decretos,
          error: false,
          loading: true,
        },
      };
    case `${GET_DECRETOS}_REJECTED`:
      return {
        ...state,
        decretos: {
          ...state.decretos,
          error: true,
          loading: false,
        },
      };
    case `${GET_DECRETOS}_FULFILLED`:
      return {
        ...state,
        decretos: {
          ...state.decretos,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_PREGUNTAS_DECRETO}_PENDING`:
      return {
        ...state,
        preguntas: {
          ...state.preguntas,
          error: false,
          loading: true,
        },
      };
    case `${GET_PREGUNTAS_DECRETO}_REJECTED`:
      return {
        ...state,
        preguntas: {
          ...state.preguntas,
          error: true,
          loading: false,
        },
      };
    case `${GET_PREGUNTAS_DECRETO}_FULFILLED`:
      return {
        ...state,
        preguntas: {
          ...state.preguntas,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_SUSTANCIAS_PLANILLA_A}_PENDING`:
      return {
        ...state,
        sustanciasA: {
          ...state.sustanciasA,
          error: false,
          loading: true,
        },
      };
    case `${GET_SUSTANCIAS_PLANILLA_A}_REJECTED`:
      return {
        ...state,
        sustanciasA: {
          ...state.sustanciasA,
          error: true,
          loading: false,
        },
      };
    case `${GET_SUSTANCIAS_PLANILLA_A}_FULFILLED`:
      return {
        ...state,
        sustanciasA: {
          ...state.sustanciasA,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_SUSTANCIAS_PLANILLA_C}_PENDING`:
      return {
        ...state,
        sustanciasC: {
          ...state.sustanciasC,
          error: false,
          loading: true,
        },
      };
    case `${GET_SUSTANCIAS_PLANILLA_C}_REJECTED`:
      return {
        ...state,
        sustanciasC: {
          ...state.sustanciasC,
          error: true,
          loading: false,
        },
      };
    case `${GET_SUSTANCIAS_PLANILLA_C}_FULFILLED`:
      return {
        ...state,
        sustanciasC: {
          ...state.sustanciasC,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_PENDING`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: true,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_REJECTED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: true,
          loading: false,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_FULFILLED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_VALIDAR_DISPONIBILIDAD_CARGA_RGRL}_PENDING`:
      return {
        ...state,
        validarDisponibilidadCargaRgrl: {
          ...state.validarDisponibilidadCargaRgrl,
          error: false,
          loading: true,
        },
      };
    case `${GET_VALIDAR_DISPONIBILIDAD_CARGA_RGRL}_REJECTED`:
      return {
        ...state,
        validarDisponibilidadCargaRgrl: {
          ...state.validarDisponibilidadCargaRgrl,
          error: true,
          loading: false,
        },
      };
    case `${GET_VALIDAR_DISPONIBILIDAD_CARGA_RGRL}_FULFILLED`:
      return {
        ...state,
        validarDisponibilidadCargaRgrl: {
          ...state.validarDisponibilidadCargaRgrl,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case POST_SEARCHED_RGRL:
      return {
        ...state,
        searchedRgrl: action.payload,
      };
    case POST_DECRETO_FORM:
      return {
        ...state,
        postRgrl: { ...state.postRgrl, decretoForm: action.payload },
      };
    case POST_GRUPO_PREGUNTAS:
      const indexToUpdate = state.postRgrl.preguntasDecreto.preguntas.findIndex(
        (element) =>
          element.idGrupoItemFormularioArt === action.payload.idGrupoItemFormularioArt
      );

      // Copia de preguntas para no modificar directamente el estado
      let preguntas = [...state.postRgrl.preguntasDecreto.preguntas];

      if (indexToUpdate !== -1) {
        preguntas[indexToUpdate] = action.payload;
      } else {
        preguntas.push(action.payload);
      }

      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          preguntasDecreto: {
            preguntas: preguntas,
          },
        },
      };
    case FORMULARIO_COMPLETO:
      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          formulariosCompletados: {
            ...state.postRgrl.formulariosCompletados,
            [action.payload]: true,
          }

        }
      }
    case POST_PREGUNTAS_DECRETO:
      return {
        ...state,
        postRgrl: { ...state.postRgrl, preguntasDecreto: action.payload },
      };
    case POST_GRUPO_SUSTANCIAS_A:
      let newArrayPlanillaA = Array.from(state.postRgrl.planillaA);
      // sabemos que nos despachan un grupo de preguntas que siempre tiene
      // el mismo tamaño, por lo que podemos sobreescribir esos items a partir
      // del index del primer objeto de ese grupo que nos mandan
      const indexReplacePlanillaA = newArrayPlanillaA.findIndex(
        (s) => s.idItemFormularioArt === action.payload[0].idItemFormularioArt
      );

      if (indexReplacePlanillaA === -1) {
        newArrayPlanillaA.push(...action.payload);
      } else {
        newArrayPlanillaA.splice(
          indexReplacePlanillaA,
          action.payload.length,
          ...action.payload
        );
      }

      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          planillaA: newArrayPlanillaA,
        },
      };
    case POST_GRUPO_SUSTANCIAS_C:
      let newArrayPlanillaC = Array.from(state.postRgrl.planillaC);
      // sabemos que nos despachan un grupo de preguntas que siempre tiene
      // el mismo tamaño, por lo que podemos sobreescribir esos items a partir
      // del index del primer objeto de ese grupo que nos mandan
      const indexReplacePlanillaC = newArrayPlanillaC.findIndex(
        (s) => s.idItemFormularioArt === action.payload[0].idItemFormularioArt
      );

      if (indexReplacePlanillaC === -1) {
        newArrayPlanillaC.push(...action.payload);
      } else {
        newArrayPlanillaC.splice(
          indexReplacePlanillaC,
          action.payload.length,
          ...action.payload
        );
      }

      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          planillaC: newArrayPlanillaC,
        },
      };
    case ADD_LOCAL_GREMIALISTA:
      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          gremialistas: [...state.postRgrl.gremialistas, action.payload],
        },
      };
    case DELETE_LOCAL_GREMIALISTA:
      const deleteGremialista = state.postRgrl.gremialistas;

      const newArrayGremialistas = deleteGremialista.filter(
        (g) => g.id !== action.payload
      );

      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          gremialistas: [...newArrayGremialistas],
        },
      };
    case ADD_LOCAL_CONTRATISTA:
      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          contratistas: [...state.postRgrl.contratistas, action.payload],
        },
      };
    case DELETE_LOCAL_CONTRATISTA:
      const deleteContratista = state.postRgrl.contratistas;
      const newArrayContratistas = deleteContratista.filter(
        (g) => g.cuit !== action.payload
      );

      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          contratistas: [...newArrayContratistas],
        },
      };
    case ADD_LOCAL_RESPONSABLE:
      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          responsables: [...state.postRgrl.responsables, action.payload],
        },
      };
    case DELETE_LOCAL_RESPONSABLE:
      const deleteResponsable = state.postRgrl.responsables;
      const newArrayResponsables = deleteResponsable.filter(
        (g) => g.cuit !== action.payload
      );

      return {
        ...state,
        postRgrl: {
          ...state.postRgrl,
          responsables: [...newArrayResponsables],
        },
      };
    case CLEAR_ESTABLECIMIENTO_CARDS:
      return {
        ...state,
        formulariosPresentados: INITIAL_STATE.formulariosPresentados,
        datosEstablecimiento: INITIAL_STATE.datosEstablecimiento,
      };
    case CLEAR_SEARCHED_RGRL:
      return {
        ...state,
        searchedRgrl: INITIAL_STATE.searchedRgrl,
      };
    case CLEAR_POST_RGRL:
      return {
        ...state,
        postRgrl: INITIAL_STATE.postRgrl,
      };
    case CLEAR_RGRL:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default rgrl;
