import { makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getDependenciasEmpleador } from "../../../../redux/actions/empleador";
import FormikAutocomplete from "../../../commons/formik/FormikAutocomplete";

const initialValues = {
  establecimiento: "",
};

const validationSchema = Yup.object().shape({
  establecimiento: Yup.string().required("Campo Obligatorio").nullable(),
});

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: 35,
    display: "flex",
  },
  button: {
    marginLeft: 20,
    width: 80,
  },
  input: {
    display: "flex",
    flexDirection: "column",
    width: 750,
  },
}));

const SearchEstablecimiento = ({
  options,
  handleSearch,
  searchModel,
  setSelectedValue,
  searchedNte,
  setOpenConfirmDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasDatosPost = useSelector((state) =>
    state.nte.postNte.decretoForm?.id ? true : false
  );

  return (
    <div>
      <Formik
        initialValues={searchModel || initialValues}
        onSubmit={handleSearch}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        <Form className={classes.formContainer}>
          <div className={classes.inputContainer}>
            <FormikAutocomplete
              className={classes.input}
              name="establecimiento"
              variant="standard"
              options={options}
              renderOption={(option) => (option.nombre ? option.nombre : "")}
              filterOptions={(option) => (option.nombre ? option.nombre : "")}
              getOptions={() => dispatch(getDependenciasEmpleador())}
              callBack={(values) => {
                if (searchedNte && hasDatosPost) {
                  setOpenConfirmDialog(true);
                  setSelectedValue(values?.id);
                } else if (!hasDatosPost && values) {
                  setSelectedValue(values?.id);
                  handleSearch({ establecimiento: values?.id });
                } else {
                  setSelectedValue("");
                  handleSearch({ establecimiento: null });
                }
              }}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SearchEstablecimiento;
