import { Grid, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperOutlined: {
    flex: 1,
    borderRadius: 5,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
  },
}));

const HeaderLabel = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={true}>
        <Paper elevation={2} className={classes.paperOutlined}>
          <div className={classes.headerDiv}>{children}</div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default HeaderLabel;
