import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResponsables } from "../../../../../redux/actions/empleador";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import { responsablesFormValidation } from "../../validation/formValidation";

const useStyles = makeStyles((theme) => ({
  newReponsableIconAligned: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ResponsablesForm = ({
  handleOpenDialog,
  model,
  handleSubmit,
  isLoading,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const responsables = useSelector((state) => state.empleador.responsables.data);

  return (
    <>
      {!isLoading ? (
        <Formik
          initialValues={model}
          onSubmit={handleSubmit}
          validationSchema={responsablesFormValidation}
          enableReinitialize={true}
        >
          {({ values }) => (
            <Form>
              <Grid container spacing={2} style={{ marginTop: 1 }}>
                <Grid item xs={6}>
                  <Card>
                    <Typography variant="h6" style={{ padding: 10 }}>
                      Responsable de los Datos Declarados
                    </Typography>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={10}>
                          <FormikAutocomplete
                            fullWidth
                            name="responsableDeDatos"
                            label={`Responsable de Datos *`}
                            value={values.responsableDeDatos}
                            variant="standard"
                            options={responsables}
                            renderOption={(option) => option.nombre}
                            filterOptions={(option) => option.nombre}
                            getOptions={() => dispatch(getResponsables())}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.newReponsableIconAligned}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleOpenDialog(true)}
                          >
                            <PersonAddAltOutlinedIcon
                              style={{ color: "white" }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card>
                    <Typography variant="h6" style={{ padding: 10 }}>
                      Responsable de Seguridad e Higiene
                    </Typography>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={10}>
                          <FormikAutocomplete
                            fullWidth
                            name="responsableDeSeguridad"
                            label={`Responsable de S y H`}
                            variant="standard"
                            value={values.responsableDeSeguridad}
                            options={responsables}
                            renderOption={(option) => option.nombre}
                            filterOptions={(option) => option.nombre}
                            getOptions={() => dispatch(getResponsables())}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className={classes.newReponsableIconAligned}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleOpenDialog(false)}
                          >
                            <PersonAddAltOutlinedIcon
                              style={{ color: "white" }}
                            />
                          </Button>
                        </Grid>{" "}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 5,
                  }}
                >
                  <Button variant="contained" color="primary" type="submit">
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : (
        <CircularLoading />
      )}
    </>
  );
};

export default ResponsablesForm;
