import { MenuItem } from "@material-ui/core";
import FormikSelect from "../../../commons/formik/FormikSelect";

const TipoContratista = ({ opcionesContratista, values }) => {
  return (
    <FormikSelect
      fullWidth
      name="idTipoContratista"
      label="Tipo de Contratista*"
      disabled={!values.resolucion || values.resolucion === "3598"}
    >
      {opcionesContratista.map(({ id, nombre }) => {
        return (
          <MenuItem value={id} key={id}>
            {nombre}
          </MenuItem>
        );
      })}
    </FormikSelect>
  );
};

export default TipoContratista;
