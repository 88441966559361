import { addMonths, compareAsc, subDays } from "date-fns";
import * as Yup from "yup";
import { validarCuilCuit } from "../../../../util/cuilcuit";

export const decretosFormValidation = Yup.object().shape({
  totalTrabajadoresEstablecimientos: Yup.number("Solo se permiten números")
    .positive("No se permiten números negativos")
    .integer("No ingresar caracteres")
    // en la tabla del back totalTrabajadoresEstablecimientos es un int
    .max(2147483647, "No puede ingresar números mayores a 2.147.483.647")
    .nullable()
    .typeError("No ingresar caracteres")
    .required("Campo obligatorio"),
  totalEstablecimientos: Yup.number("Solo se permiten números")
    .positive("No se permiten números negativos")
    .integer("No ingresar caracteres")
    // en la tabla del back totalEstablecimientos es un smallint
    .max(32767, "No puede ingresar números mayores a 32.767")
    .nullable()
    .typeError("No ingresar caracteres")
    .required("Campo obligatorio"),
  superficie: Yup.string()
    .matches(/^[0-9]+(,[0-9]+)?$/, "Solo ingrese comas y números")
    .test({
      // en la tabla del back totalEstablecimientos es un decimal(10, 2)
      name: "cantidadDigitosDecimales",
      message: "Máximo 2 dígitos después de la coma",
      test: function (value) {
        if (value?.includes(",")) {
          return value?.match(/^[0-9]+(,\d{1,2})?$/);
        } else {
          return true;
        }
      },
    })
    .test({
      name: "cantidadTotalDigitos",
      message: "Máximo 8 dígitos enteros",
      test: function (value) {
        return value?.split(",")[0]?.length <= 8;
      },
    })
    .nullable()
    .typeError("No ingresar caracteres")
    .required("Campo obligatorio"),
  observaciones: Yup.string()
    .max(100, "Máximo permitido: 100 caracteres")
    .optional(),
});

export const validatePreguntaTable = Yup.object({
  itemsFormularioArt: Yup.array().of(
    Yup.object().shape({
      resultado: Yup.string().required("Debes seleccionar una respuesta"),
      fechaRegulacion: Yup.date()
        .nullable()
        .when("resultado", {
          is: "0",
          then: Yup.date()
            .min(
              subDays(new Date(), 1),
              "La fecha  de regulación no puede ser menor a la fecha de hoy"
            )
            .typeError("Fecha inválida")
            .nullable()
            .required("La fecha de regulación es requerida")
            .test({
              name: "valorMaximoFecha",
              message:
                "La fecha de regulación no puede ser mayor a 15 meses de la fecha de presentación",
              test: function (value) {
                // Compare the two dates and return
                // 1 if the first date is after the second,
                // -1 if the first date is before the second
                // or 0 if dates are equal.
                if (compareAsc(addMonths(new Date(), 15), value) >= 0) {
                  return true;
                }
                return false;
              },
            })
            .test({
              name: "fechaRegulacion",
              message:
                "La fecha de regulación debe ser menor a la fecha de verificación",
              test: function (value) {
                if (value < this.parent.fechaLimiteVerificacion) {
                  return true;
                }
                return false;
              },
            }),
        }),
      fechaLimiteVerificacion: Yup.date()
        .nullable()
        .when("resultado", {
          is: "0",
          then: Yup.date()
            .min(
              new Date(),
              "La fecha  de verificación no puede ser menor a la fecha de hoy"
            )
            .typeError("Fecha inválida")
            .required("La fecha límite de verificación es requerida")
            .test({
              name: "valorMaximoFecha",
              message:
                "La fecha de verificación no puede ser mayor a 15 meses de la fecha de presentación",
              test: function (value) {
                if (compareAsc(addMonths(new Date(), 15), value) >= 0) {
                  return true;
                }
                return false;
              },
            })
            .test({
              name: "fechaLimiteVerificacion",
              message:
                "La fecha de verificación debe ser mayor a la fecha de regulación",
              test: function (value) {
                if (value > this.parent.fechaRegulacion) {
                  return true;
                }
                return false;
              },
            }),
        }),
    })
  ),
});


export const validateNewResponsable = Yup.object({
  cargoEmpresa: Yup.string().nullable().required("Campo obligatorio"),
  apellido: Yup.string()
    .max(50, "Máximo 50 caracteres")
    .required("Campo obligatorio"),
  nombres: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Ingrese sólo letras y espacios")
    .max(50, "Máximo 50 caracteres")
    .optional(),
  cuit: Yup.string()
    .matches(/^\d{11}$/, "El CUIL debe contener 11 dígitos numéricos")
    .required("Campo obligatorio")
    .test({
      name: "cuitValido",
      message: "Debe ingresar un CUIT válido",
      test: function (value) {
        if (value) {
          return validarCuilCuit(value);
        }
      },
    }),
  tipoDocumento: Yup.string().nullable().required("Campo obligatorio"),
  numeroDocumento: Yup.number()
    .min(10000000, "Mínimo 8 dígitos")
    .max(99999999, "Máximo 8 dígitos")
    .integer("No ingrese caracteres especiales")
    .nullable()
    .typeError("Ingrese sólo números")
    .required("Campo obligatorio"),
  telefono: Yup.string()
    .matches(
      /^[0-9()+-\s]*$/,
      "Solo se permiten números y los caracteres ( ) + - y espacio"
    )
    .max(50, "El número de teléfono debe tener como máximo 50 caracteres")
    .optional(),
  email: Yup.string()
    .max(255, "Máximo 255 caracteres")
    .email("Ingrese una dirección de correo válida")
    .optional(),
  tipoMatricula: Yup.number().nullable().required("Campo obligatorio"),
  nroMatricula: Yup.string()
    .max(50, "Máximo 50 caracteres")
    .matches(/^\d*$/, "Ingrese sólo números")
    .required("Campo obligatorio"),
  horasAsignadas: Yup.number("Sólo se permiten números")
    .max(8760, "Máximo 8760 horas")
    .positive("Ingrese números positivos")
    .integer("No ingrese caracteres especiales")
    .nullable()
    .typeError("Ingrese sólo números")
    .required("Campo obligatorio"),
  titulo: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Ingrese sólo letras y espacios")
    .max(100, "Máximo 100 caracteres")
    .optional(),
  matriculaOtorgadaPor: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Ingrese sólo letras y espacios")
    .max(100, "Máximo 100 caracteres")
    .required("Campo obligatorio"),
});

export const validateRequiredFields = (values, model) => {
  const errors = {};
  const errorMessage = "Campo obligatorio";

  for (var value in values) {
    //valida que el campo exista en los datos de la nomina para validar su obligatoriedad
    if (model[value]) {
      if (model[value].obligatorio && !values[value]) {
        errors[value] = errorMessage;
      }
    }
  }

  return errors;
};
