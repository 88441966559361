import { FormControlLabel, Grid, Radio, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import FormikRadio from "../../../../components/commons/formik/FormikRadio";
import FormikTextField from "../../../../components/commons/formik/FormikTextField";
import FormikActions from "../../../commons/formik/FormikActions";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import { useStyles } from "../styles/formStyles";
import { decretosFormValidation } from "../validation/formValidation";

const DecretoForm = ({
  handleSubmit,
  handleChange,
  data,
  decretoSeleccionado,
}) => {
  const classes = useStyles();

  return (
    <>
      <FechaPresentacionLabel />
      <Formik
        initialValues={{
          ...decretoSeleccionado,
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={decretosFormValidation}
      >
        {({ values }) => (
          <Form>
            <Grid container spacing={3} className={classes.flexDirectionColumn}>
              <Grid item container xs={12}>
                <Grid item xs={6} className={classes.paddingContainer}>
                  <Typography>Seleccionar el Decreto</Typography>
                  <div className={classes.radioContainer}>
                    {data.opciones.map((decreto, index) => (
                      <FormikRadio name="decretos" row key={index}>
                        <div className={classes.formikRadio}>
                          <FormControlLabel
                            name="nombre"
                            control={
                              <Radio
                                onChange={() => {
                                  handleChange(decreto);
                                }}
                                checked={decreto.id === decretoSeleccionado.id}
                              />
                            }
                            label={`Decreto ${decreto.nombre}`}
                            labelPlacement="end"
                          />
                        </div>
                      </FormikRadio>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={6} className={classes.paddingContainer}>
                  <Typography style={{ textAlign: "center" }}>
                    Datos del Establecimiento
                  </Typography>
                  <div className={classes.textInputGroupContainer}>
                    <div className={classes.formikInput}>
                      <FormikTextField
                        fullWidth={true}
                        name="totalTrabajadoresEstablecimientos"
                        label="Total Trabajadores del Establecimiento *"
                      />
                    </div>
                    <div className={classes.formikInput}>
                      <FormikTextField
                        fullWidth={true}
                        name="totalEstablecimientos"
                        label="Total de Establecimientos *"
                      />
                    </div>
                    <div className={classes.formikInput}>
                      <FormikTextField
                        fullWidth={true}
                        name="superficie"
                        label="Superficie M2 *"
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.paddingContainer}>
                  <FormikTextField
                    fullWidth={true}
                    name="observaciones"
                    label="Observaciones"
                    type="text"
                    variant="outlined"
                    multiline
                    maxRows={15}
                    minRows={2}
                  />
                  {values.observaciones.length > 0 && (
                    <Typography variant="caption">
                      {values.observaciones.length} caracteres
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <FormikActions submit="Siguiente" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DecretoForm;
