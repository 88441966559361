import { Form, Formik } from "formik";

import { Grid, Typography } from "@material-ui/core";
import FormikActions from "../../../commons/formik/FormikActions";
import FormikDatePicker from "../../../commons/formik/FormikDatePicker";
import FormikTextField from "../../../commons/formik/FormikTextField";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import TitleWithUnderline from "../commons/TitleWithUnderline";
import { useStyles } from "../styles/formStyle";
import { demolitionFormValidation } from "../validation/formValidation";

const DemolicionForm = ({ initialValues, formModel, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={formModel}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={demolitionFormValidation}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FechaPresentacionLabel />
            </Grid>

            <TitleWithUnderline title="RESOLUCIÓN 550/11" />

            <Grid container className={classes.gridContainer}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Demoliciones de edificios mayores a 3 metros
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikDatePicker
                  name="demolicion.fechaInicioDemolicion"
                  label="Fecha Inicio"
                  callback={(value) => {
                    if (value === null) {
                      setFieldValue("demolicion.fechaFinDemolicion", null);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <FormikDatePicker
                  name="demolicion.fechaFinDemolicion"
                  label="Fecha Fin"
                  disabled={!values.demolicion.fechaInicioDemolicion}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.gridContainer}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Excavaciones de subsuelos y/o submuraciones
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikDatePicker
                  name="demolicion.fechaInicioExcavacion"
                  label="Fecha Inicio"
                  callback={(value) => {
                    if (value === null) {
                      setFieldValue("demolicion.fechaFinExcavacion", null);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikDatePicker
                  name="demolicion.fechaFinExcavacion"
                  label="Fecha Fin"
                  disabled={!values.demolicion.fechaInicioExcavacion}
                />
              </Grid>
            </Grid>

            <TitleWithUnderline title="EXCAVACIONES A CIELO ABIERTO MAYORES A 1.2 mts" />

            <Grid container className={classes.gridContainer}>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                  Excavaciones a cielo abierto
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <FormikDatePicker
                  name="demolicion.fechaInicioExcavacionCA"
                  label="Fecha Inicio"
                  callback={(value) => {
                    if (value === null) {
                      setFieldValue("demolicion.fechaFinExcavacionCA", null);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormikDatePicker
                  name="demolicion.fechaFinExcavacionCA"
                  label="Fecha Fin"
                  disabled={!values.demolicion.fechaInicioExcavacionCA}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} className={classes.paddingContainer}>
              <FormikTextField
                name="demolicion.excavacionCAOtros"
                label="Otras"
                variant="outlined"
                fullWidth
                multiline
                maxRows={15}
                minRows={2}
              />
              {values?.demolicion?.excavacionCAOtros.length > 0 && (
                <Typography variant="caption">
                  {values.demolicion.excavacionCAOtros.length} caracteres
                </Typography>
              )}
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions submit="Siguiente" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DemolicionForm;
