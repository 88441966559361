import { addDays, endOfDay, subMonths } from "date-fns";

import * as Yup from "yup";
import { validarCuilCuit } from "../../../../util/cuilcuit";
import {
  dateIsBefore,
  isDecimal as hasTwoDecimals,
} from "../../../../util/validacionesYup";
import { validacionCalle, validacionCodigoPostal, validacionDepartamento, validacionKm, validacionLocalidad, validacionNumeroCalle, validacionPais, validacionPiso, validacionProvincia } from "../../../commons/yup/ValidacionesLocalidades";

const cuitRegexGuiones = /^(20|23|24|27|30|33|34)-\d{8}-\d$/;
const cuitRegexSinGuiones = /^(20|23|24|27|30|33|34)\d{8}\d$/;

export const validateNewEstablecimiento = Yup.object().shape({
  nombre: Yup.string().required("El nombre del establecimiento es obligatorio").max(30, "Máximo 30 caracteres"),
  fechaInicioActividad: Yup.date()
    .nullable()
    .required("Campo obligatorio")
    .typeError('Fecha inválida')
    .max(
      addDays(endOfDay(new Date()), -1),
      "La fecha de inicio de actividad no puede ser igual ni posterior a la fecha actual"
    )
    .typeError('Fecha inválida'),
  actividad: Yup.string().required("Campo obligatorio").nullable(),
  esTercero: Yup.boolean(),
  cuit: Yup.string()
    .test({
      name: "formatoCUIT",
      message:
        "CUIT inválido. Debe tener el formato XX-XXXXXXXXX-X o XXXXXXXXXXX",
      test: function (value) {
        if (!value) return true;
        // Validar si el CUIT tiene guiones o no
        if (value.includes("-")) {
          return cuitRegexGuiones.test(value); // Validar con guiones
        } else {
          return cuitRegexSinGuiones.test(value); // Validar sin guiones
        }
      },
    })
    .test({
      name: "cuitValido",
      message: "Debe ingresar un CUIT válido",
      test: function (value) {
        if (value) {
          // Remover los guiones si están presentes antes de validar
          const cuitSinGuiones = value.replace(/-/g, "");
          return validarCuilCuit(cuitSinGuiones, true, true);
        }
        return true;
      },
    })
    .when("esTercero", {
      is: true,
      then: Yup.string()
        .required("Campo obligatorio")

    }),
  km: validacionKm,

  latitud: Yup.number()
    .test({
      name: "latitudValida",
      message: "Debe ingresar una latitud válida entre -90 y 90 grados",
      test: function (value) {
        if (value !== null && value !== undefined) {
          const isNumber = !isNaN(value); // Verifica si es un número
          const isWithinRange = value >= -90 && value <= 90; // Verifica el rango
          return isNumber && isWithinRange; // Solo es válido si es un número y está en el rango
        }
        return true; // Si es nulo o indefinido, es válido
      },
    })
    .typeError("La latitud debe ser un numero")
    .when("km", {
      is: true,
      then: Yup.number()
        .required("Campo obligatorio")
        .typeError("La latitud debe ser un numero")

    }),

  longitud: Yup.number()
    .test({
      name: "longitudValida",
      message: "Debe ingresar una longitud válida entre -180 y 180 grados",
      test: function (value) {
        if (value !== null && value !== undefined) {
          const isNumber = !isNaN(value); // Verifica si es un número
          const isWithinRange = value >= -180 && value <= 180; // Verifica el rango
          return isNumber && isWithinRange; // Solo es válido si es un número y está en el rango
        }
        return true; // Si es nulo o indefinido, es válido
      },
    })
    .typeError("La longitud debe ser un numero")
    .when("km", {
      is: true,
      then: Yup.number()
        .required("Campo obligatorio")
        .typeError("La longitud debe ser un numero")
    }),
  piso: validacionPiso,
  departamento: validacionDepartamento,
  pais: validacionPais.required("Campo obligatorio"),
  provincia: validacionProvincia.required("Campo obligatorio"),
  localidad: validacionLocalidad.required("Campo obligatorio"),
  calle: validacionCalle.required("Campo obligatorio"),
  codigoPostal: validacionCodigoPostal.required("Campo obligatorio"),
  entreCalle: validacionCalle,
  entreCalle1: validacionCalle,
  numero: validacionNumeroCalle("km"),
});

export const identificacionFormValidation = Yup.object().shape({
  denominacionObra: Yup.string()
    .required("Campo obligatorio")
    .max(255, "Máximo 255 caracteres"),
  numeroObraSRT: Yup.number()
    .typeError("No ingresar caracteres")
    .min(0, "No ingresar caracteres")
    .integer("No ingresar caracteres")
    .max(9999, "Máximo 4 digitos")
    .required("Campo obligatorio"),
  fechaInicioObra: Yup.date()
    .nullable()
    .required("Campo obligatorio")
    .typeError('Fecha inválida')
    .test({
      name: "fechaLimiteVerificacion",
      message:
        "La fecha de inicio de actividad debe ser menor o igual a la fecha de fin de estimada", // TODO: Ver si es el test o el mensaje
      test: (value, testContext) => {
        if (value && testContext.parent.fechaFinObra) {
          return dateIsBefore(value, testContext.parent.fechaFinObra);
        }

        return true;
      },
    }),
  idDependenciaEmpleador: Yup.string()
    .nullable()
    .required("Campo obligatorio"),
  fechaFinObra: Yup.date()
    .nullable()
    .required("Campo obligatorio")
    .typeError('Fecha inválida')
    .test({
      name: "fechaLimiteVerificacion",
      message:
        "La fecha de finalizacion estimada debe ser mayor o igual a la fecha de inicio de actividad",
      test: (value, testContext) => {
        if (!value || !testContext.parent.fechaInicioObra) {
          return true;
        }

        return dateIsBefore(testContext.parent.fechaInicioObra, value);
      },
    })
    .test({
      name: "valorMinimoFecha",
      message:
        "La fecha de finalizacion no puede ser menor a un mes antes de la fecha de presentacion",
      test: function (value) {
        if (!value) {
          return true;
        }
        return dateIsBefore(subMonths(new Date(), 1), value);
      },
    }),
});

export const caracteristicasFormValidation = Yup.object().shape({
  resolucion: Yup.string().required("Campo obligatorio"),
  idTipoContratista: Yup.string(),
  superficieAConstruir: Yup.string()
    .matches(/^[0-9]+(,[0-9]+)?$/, "Solo ingrese comas y números")
    .required("Campo obligatorio")
    .test({
      name: "cantidadDigitosDecimales",
      message: "Máximo 2 dígitos después de la coma",
      test: (value) => hasTwoDecimals(value),
    })
    .test({
      name: "cantidadTotalDigitos",
      message: "Máximo 8 dígitos enteros",
      test: function (value) {
        if (value) {
          return value?.split(",")[0]?.length <= 8;
        } else {
          return true;
        }
      },
    }),
  numeroPlantas: Yup.number()
    .required("Campo obligatorio")
    .min(0, "No se permiten números negativos")
    .integer("No ingresar caracteres")
    .max(2147483647, "No puede ingresar números mayores a 2.147.483.647")
    .nullable(),
  observaciones: Yup.string()
    .max(8000, "Máximo 8000 caracteres")
    .nullable(),
});

/***
Las Fecha Inicio: between Fecha Inicio de obra y fin de obra

La fecha fin: between Fecha Inicio (demolición o excavación según corresponda) y fin de obra

se puede dar que el inicio y fin sean iguales
 
No se puede enviar una fecha fin sin fecha de inciio
*/
const validateInitialDemolitionDate = (fechaFin) => {
  return Yup.date()
    .nullable()
    .typeError('Fecha inválida')
    .test({
      name: "fechaMinimaVerificacion",
      message:
        "La Fecha de Inicio debe estar entre la Fecha de Inicio de obra y la Fecha Fin de obra",
      test: function (value, testContext) {
        if (value) {
          return dateIsBefore(
            testContext.from[1].value.identificacion.fechaInicioObra,
            value
          );
        }
        return true;
      },
    })
    .test({
      name: "fechaMaximaVerificacion",
      message:
        "La Fecha Inicio debe ser menor o igual a la Fecha de Fin de obra",
      test: (value, testContext) => {
        if (value) {
          return dateIsBefore(
            value,
            testContext.from[1].value.identificacion.fechaFinObra
          );
        }
        return true;
      },
    });
};

const validateEndDemolitionDate = (fechaInicio) => {
  return Yup.date()
    .nullable()
    .typeError('Fecha inválida')
    .when(`${fechaInicio}`, {
      is: (f) => {
        return f !== null;
      },
      then: Yup.date()
        .nullable()
        .required("Fecha de Fin es requerida si existe Fecha de Inicio")
        .typeError('Fecha inválida')
        .test({
          name: "fechaMinimaVerificacion",
          message:
            "La Fecha de Fin debe ser mayor a la Fecha de Inicio",
          test: (value, testContext) => {
            if (value) {
              return dateIsBefore(testContext.parent[fechaInicio], value);
            }
            return true;
          },
        })
        .test({
          name: "fechaMaximaVerificacion",
          message:
            "La Fecha Fin debe ser menor o igual a la Fecha de Fin de obra",
          test: function (value, testContext) {
            if (value) {
              return dateIsBefore(
                value,
                testContext.from[1].value.identificacion.fechaFinObra
              );
            }
            return true;
          },
        }),
    });
};

export const demolitionFormValidation = Yup.object().shape({
  demolicion: Yup.object().shape({
    fechaInicioDemolicion: validateInitialDemolitionDate(),
    fechaFinDemolicion: validateEndDemolitionDate("fechaInicioDemolicion"),
    fechaInicioExcavacion: validateInitialDemolitionDate(),
    fechaFinExcavacion: validateEndDemolitionDate("fechaInicioExcavacion"),
    fechaInicioExcavacionCA: validateInitialDemolitionDate(),
    fechaFinExcavacionCA: validateEndDemolitionDate("fechaInicioExcavacionCA"),
    excavacionCAOtros: Yup.string()
      .max(8000, "Máximo 8000 caracteres")
      .nullable(),
  }),
});

export const validateActividadADesarrollar = Yup.object({
  opciones: Yup.array().of(
    Yup.object().shape({
      resultado: Yup.string().oneOf(["1", "0"]),
      seleccionOpcion: Yup.string().nullable().test({
        name: "seleccionOpcionEsObligatorio",
        message: "Campo obligatorio",
        test: function (value, context) {
          const { parent } = context;
          if (parent.resultado === "1" && parent.opciones?.length > 0 && !parent.seleccionOpcion) {
            return false;
          }
          return true
        },
      })
    }),

  ),
});
