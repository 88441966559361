import { Button, Typography, useTheme } from "@material-ui/core";
import { Grid, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import CircularLoading from "../../../commons/loading/CircularLoading";
import { useStyles } from "../styles/formStyles";
import DatosDecretoPresentacion from "./lists/DatosDecretoPresentacion";
import DatosEstablecimientoPresentacion from "./lists/DatosEstablecimientoPresentacion";

const PresentacionContainer = ({ handleSubmitForm, isDownloadingPdf }) => {
  const classes = useStyles();
  const theme = useTheme();
  const datosEstablecimiento = useSelector(
    (state) => state.rgrl.datosEstablecimiento?.data
  );
  const datosDecreto = useSelector((state) => state.rgrl.postRgrl.decretoForm);

  return (
    <div className={classes.paper}>
      {!isDownloadingPdf ? (
        <Paper>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.titulo}
                color="primary"
                variant="h5"
              >
                Presentación RGRL
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6} display="flex" justifyContent="center">
                <DatosEstablecimientoPresentacion data={datosEstablecimiento} />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="center">
                <DatosDecretoPresentacion data={datosDecreto} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              paddingBottom={5}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSubmitForm()}
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <div className={classes.loadingContainer}>
          <CircularLoading
            style={{ color: `${theme.palette.tertiary.main}` }}
          />
        </div>
      )}
    </div>
  );
};

export default PresentacionContainer;
