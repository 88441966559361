import { toast } from "react-toastify";
import { parseBlobToObject } from "../../util/files";
import { logout } from "../actions/auth";
import { setErrorMessage } from "../actions/error";

const ErrorMiddleware = (store) => (next) => async (action) => {
  if (action.type.includes("persist")) return next(action);

  if (action.type.includes("_REJECTED")) {
    store.dispatch(
      setErrorMessage(
        action.payload?.request?.status,
        action.payload?.data?.Message
      )
    );

    let errorMessage =
      "Estamos teniendo problemas para conectarnos con el servidor, por favor inténtelo nuevamente.";
    if (action.payload?.response?.data instanceof Blob) {
      let blobObject = await parseBlobToObject(action.payload.response.data);
      if (blobObject?.Message) {
        errorMessage = blobObject.Message;
      }
    }

    if (action.payload?.response?.data?.Message) {
      errorMessage = action.payload?.response?.data?.Message;
    } else if (
      action.payload?.request?.status === 401 ||
      action.payload?.request?.status === 0
    ) {
      errorMessage = "Sesión expirada";
      store.dispatch(logout());
      return toast.error(errorMessage, {
        toastId: 401,
      });
    }
    toast.error(errorMessage);
  }

  return next(action);
};

export default ErrorMiddleware;
