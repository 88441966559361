import {
  ADD_LOCAL_RESPONSABLE,
  CLEAR_DATOS_ESTABLECIMIENTOS,
  CLEAR_NUEVA_OBRA,
  DELETE_LOCAL_RESPONSABLE,
  GET_ACTIVIDADES_EMPLEADOR,
  GET_DATOS_ESTABLECIMIENTOS,
  GET_ESTABLECIMIENTOS,
  GET_PREGUNTAS_ACTIVIDAD,
  GET_PREGUNTAS_TIPO_DE_OBRA,
  GET_TIPOS_CONTRATISTA,
  POST_ACTIVIDAD,
  POST_CARACTERISTICA_FORM,
  POST_CARGA_NUEVA_OBRA,
  POST_DEMOLICION,
  POST_DEPENDENCIA_EMPLEADOR,
  POST_IDENTIFICACION_FORM,
  POST_TIPO_DE_OBRA,
  VALIDAR_CARGA_NUEVA_OBRA,
} from "../actions/obra";

const INITIAL_STATE = {
  tipoContratista: {
    loading: false,
    error: false,
    data: [],
  },
  actividadesEmpleador: {
    loading: false,
    error: false,
    data: [],
  },
  datosEstablecimiento: {
    loading: false,
    error: false,
    data: [],
  },
  establecimientos: {
    loading: false,
    error: false,
    data: [],
  },
  postDependenciaEmpleador: {
    loading: false,
    error: false,
    data: [],
  },
  tipoDeObra: {
    loading: false,
    error: false,
    data: [],
  },
  validarCargaNuevaObra: {
    loading: false,
    error: false,
    data: [],
  },
  actividad: {
    loading: false,
    error: false,
    data: [],
  },
  postNuevaObra: {
    identificacion: {},
    caracteristica: {},
    tipoDeObra: [],
    actividad: {
      preguntas: [],
    },
    demolicion: {},
    responsables: [],
  },
};

const obra = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case `${GET_TIPOS_CONTRATISTA}_PENDING`:
      return {
        ...state,
        tipoContratista: {
          ...state.tipoContratista,
          error: false,
          loading: true,
        },
      };
    case `${GET_TIPOS_CONTRATISTA}_REJECTED`:
      return {
        ...state,
        tipoContratista: {
          ...state.tipoContratista,
          error: true,
          loading: false,
        },
      };
    case `${GET_TIPOS_CONTRATISTA}_FULFILLED`:
      return {
        ...state,
        tipoContratista: {
          ...state.tipoContratista,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_PENDING`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: true,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_REJECTED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: true,
          loading: false,
        },
      };
    case `${GET_DATOS_ESTABLECIMIENTOS}_FULFILLED`:
      return {
        ...state,
        datosEstablecimiento: {
          ...state.datosEstablecimiento,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_PREGUNTAS_TIPO_DE_OBRA}_PENDING`:
      return {
        ...state,
        tipoDeObra: {
          ...state.tipoDeObra,
          error: false,
          loading: true,
        },
      };
    case `${GET_PREGUNTAS_TIPO_DE_OBRA}_REJECTED`:
      return {
        ...state,
        tipoDeObra: {
          ...state.tipoDeObra,
          error: true,
          loading: false,
        },
      };
    case `${GET_PREGUNTAS_TIPO_DE_OBRA}_FULFILLED`:
      return {
        ...state,
        tipoDeObra: {
          ...state.tipoDeObra,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_ACTIVIDADES_EMPLEADOR}_PENDING`:
      return {
        ...state,
        actividadesEmpleador: {
          ...state.actividadesEmpleador,
          error: false,
          loading: true,
        },
      };
    case `${GET_ACTIVIDADES_EMPLEADOR}_REJECTED`:
      return {
        ...state,
        actividadesEmpleador: {
          ...state.actividadesEmpleador,
          error: true,
          loading: false,
        },
      };
    case `${GET_ACTIVIDADES_EMPLEADOR}_FULFILLED`:
      return {
        ...state,
        actividadesEmpleador: {
          ...state.actividadesEmpleador,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${POST_DEPENDENCIA_EMPLEADOR}_PENDING`:
      return {
        ...state,
        postDependenciaEmpleador: {
          ...state.postDependenciaEmpleador,
          error: false,
          loading: true,
        },
      };
    case `${POST_DEPENDENCIA_EMPLEADOR}_REJECTED`:
      return {
        ...state,
        postDependenciaEmpleador: {
          ...state.postDependenciaEmpleador,
          error: true,
          loading: false,
        },
      };
    case `${POST_DEPENDENCIA_EMPLEADOR}_FULFILLED`:
      return {
        ...state,
        postDependenciaEmpleador: {
          ...state.postDependenciaEmpleador,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_ESTABLECIMIENTOS}_PENDING`:
      return {
        ...state,
        establecimientos: {
          ...state.establecimientos,
          error: false,
          loading: true,
        },
      };
    case `${GET_ESTABLECIMIENTOS}_REJECTED`:
      return {
        ...state,
        establecimientos: {
          ...state.establecimientos,
          error: true,
          loading: false,
        },
      };
    case `${GET_ESTABLECIMIENTOS}_FULFILLED`:
      return {
        ...state,
        establecimientos: {
          ...state.establecimientos,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${GET_PREGUNTAS_ACTIVIDAD}_PENDING`:
      return {
        ...state,
        actividad: {
          ...state.actividad,
          error: false,
          loading: true,
        },
      };
    case `${GET_PREGUNTAS_ACTIVIDAD}_REJECTED`:
      return {
        ...state,
        actividad: {
          ...state.actividad,
          error: true,
          loading: false,
        },
      };
    case `${GET_PREGUNTAS_ACTIVIDAD}_FULFILLED`:
      return {
        ...state,
        actividad: {
          ...state.actividad,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case POST_IDENTIFICACION_FORM:
      return {
        ...state,
        postNuevaObra: {
          ...state.postNuevaObra,
          identificacion: action.payload,
        },
      };
    case POST_CARACTERISTICA_FORM:
      return {
        ...state,
        postNuevaObra: {
          ...state.postNuevaObra,
          caracteristica: action.payload,
        },
      };
    case POST_DEMOLICION:
      return {
        ...state,
        postNuevaObra: {
          ...state.postNuevaObra,
          demolicion: action.payload,
        },
      };

    case ADD_LOCAL_RESPONSABLE:
      return {
        ...state,
        postNuevaObra: {
          ...state.postNuevaObra,
          responsables: [...state.postNuevaObra.responsables, action.payload],
        },
      };
    case DELETE_LOCAL_RESPONSABLE:
      const deleteResponsable = state.postNuevaObra.responsables;
      const newArrayResponsables = deleteResponsable.filter(
        (g) => g.cuit !== action.payload
      );

      return {
        ...state,
        postNuevaObra: {
          ...state.postNuevaObra,
          responsables: [...newArrayResponsables],
        },
      };

    case POST_TIPO_DE_OBRA:
      const indexToUpdate = state.postNuevaObra.tipoDeObra.findIndex(
        (element) =>
          element.idGrupoItemFormularioArt ===
          action.payload.idGrupoItemFormularioArt
      );

      // Copia de preguntas para no modificar directamente el estado
      let preguntas = [...state.postNuevaObra.tipoDeObra];

      if (indexToUpdate !== -1) {
        preguntas[indexToUpdate] = action.payload;
      } else {
        preguntas.push(action.payload);
      }

      return {
        ...state,
        postNuevaObra: {
          ...state.postNuevaObra,
          tipoDeObra: preguntas,
        },
      };
    case POST_ACTIVIDAD:
      const indexToUpdateActividad =
        state.postNuevaObra.actividad.preguntas.findIndex(
          (element) =>
            element.idGrupoItemFormularioArt ===
            action.payload.idGrupoItemFormularioArt
        );

      // Copia de preguntas para no modificar directamente el estado
      let preguntasActividad = [...state.postNuevaObra.actividad.preguntas];

      if (indexToUpdateActividad !== -1) {
        preguntasActividad[indexToUpdateActividad] = action.payload;
      } else {
        preguntasActividad.push(action.payload);
      }

      return {
        ...state,
        postNuevaObra: {
          ...state.postNuevaObra,
          actividad: {
            preguntas: preguntasActividad,
          },
        },
      };

    case `${POST_CARGA_NUEVA_OBRA}_PENDING`:
      return {
        ...state,
        postTipoDeObra: {
          ...state.postTipoDeObra,
          error: false,
          loading: true,
        },
      };
    case `${POST_CARGA_NUEVA_OBRA}_REJECTED`:
      return {
        ...state,
        postTipoDeObra: {
          ...state.postTipoDeObra,
          error: true,
          loading: false,
        },
      };
    case `${POST_CARGA_NUEVA_OBRA}_FULFILLED`:
      return {
        ...state,
        postTipoDeObra: {
          ...state.postTipoDeObra,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case `${VALIDAR_CARGA_NUEVA_OBRA}_PENDING`:
      return {
        ...state,
        validarCargaNuevaObra: {
          ...state.validarCargaNuevaObra,
          error: false,
          loading: true,
        },
      };
    case `${VALIDAR_CARGA_NUEVA_OBRA}_REJECTED`:
      return {
        ...state,
        validarCargaNuevaObra: {
          ...state.validarCargaNuevaObra,
          error: true,
          loading: false,
        },
      };
    case `${VALIDAR_CARGA_NUEVA_OBRA}_FULFILLED`:
      return {
        ...state,
        validarCargaNuevaObra: {
          ...state.validarCargaNuevaObra,
          error: false,
          loading: false,
          data: action.payload.data,
        },
      };
    case CLEAR_DATOS_ESTABLECIMIENTOS:
      return {
        ...state,
        datosEstablecimiento: INITIAL_STATE.datosEstablecimiento,
      };
    case CLEAR_NUEVA_OBRA:
      return {
        ...state,
        postNuevaObra: INITIAL_STATE.postNuevaObra,
        datosEstablecimiento: INITIAL_STATE.datosEstablecimiento,
        establecimientos: INITIAL_STATE.establecimientos,
      };
    default:
      return state;
  }
};

export default obra;
