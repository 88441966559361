import { Button } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
    addLocalResponsable,
    deleteLocalResponsable,
} from "../../../../redux/actions/obra";
import ConfirmDialog from "../../../commons/dialog/ConfirmDialog";
import ResponsablesContainer from "../../../commons/responsables/ResponsablesContainer";
import { useStyles } from "../styles/formStyle";

const ResponsablesContainerObra = ({ handleSubmitLastForm, handleCancel }) => {
    const classes = useStyles();
    const listaResponsables = useSelector((state) => state.obra.postNuevaObra.responsables)

    const [openCancel, setOpenCancel] = useState();

    const openCancelWindow = useCallback(() => {
        setOpenCancel(true);
    }, [setOpenCancel])

    const closeCancelWindow = useCallback(() => {
        setOpenCancel(false);
    }, [setOpenCancel])

    return (
        <>
            <div className={classes.tableContainer} >
                <ResponsablesContainer
                    listaResponsables={listaResponsables}
                    addLocalResponsable={addLocalResponsable}
                    deleteLocalResponsable={deleteLocalResponsable}
                    isRequired={false}
                />
            </div>
            <div className={classes.buttonContainer}>
                <Button color="secondary" onClick={openCancelWindow}>
                    Cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitLastForm}
                >
                    Finalizar
                </Button>

            </div>

            <ConfirmDialog
                open={openCancel}
                handleClose={closeCancelWindow}
                handleConfirm={handleCancel}
                loading={false}

                title="Cancelar"
                text="¿Está seguro de que desea cancelar? Toda la carga realizada en el formulario no se guardará"
            />
        </>

    )
}

export default ResponsablesContainerObra;