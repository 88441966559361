import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useStyles } from "../../prevencion/rgrl/styles/formStyles";
import FormikActions from "../formik/FormikActions";
import FormikSelect from "../formik/FormikSelect";
import RequeridoLabel from "../labels/RequeridoLabel";
import CustomStepper from "../stepper/CustomStepper";
import { validatePlanillaTable } from "./defaultPlanillaValidation";
import TableTitle from "./TableTitle";

const initialValues = {
  idItemFormularioArt: "",
  secuencia: "",
  pregunta: "",
  resultado: "0",
  seleccionOpcion: "", // Agregamos este campo para el Autocomplete
};

const PlanillaTable = ({
  handleSubmit,
  data,
  tituloGrupo,
  contador,
  totalGrupos,
  handleGoBack,
  fromRgrl,
  columnLabel = "",
  capitalizeTitle = true,
  construccion, // This flag controls if we should add the construction column
  validationSchema = validatePlanillaTable
}) => {
  const classes = useStyles();
  const [columns, setColumns] = useState([
    { id: `column${columnLabel}`, label: `${columnLabel}` },
    { id: "si", label: "Sí" },
    { id: "no", label: "No" },
  ]);

  // Add the "Construcción" column if the `construccion` prop is true
  useEffect(() => {
    if (construccion) {
      setColumns((prevColumns) => [
        ...prevColumns,
        { id: "construccion", label: "Construcción" },
      ]);
    }
  }, [construccion]);

  return (
    <>
      <TableTitle
        title={capitalizeTitle
          ? `${tituloGrupo.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          )}`
          : tituloGrupo
        }
      />
      <Formik
        initialValues={{
          opciones: data.reduce((opciones, sustancia, index) => {
            opciones[index] = { ...initialValues, ...sustancia };
            return opciones;
          }, []),
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <FieldArray name="opciones" validateOnChange={false}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.opciones.map((sustancia, sustanciaIndex) => {
                      return (
                        <TableRow key={sustanciaIndex}>
                          <TableCell>{sustancia.pregunta}</TableCell>
                          <TableCell>
                            <RadioGroup
                              name={`opciones.${sustanciaIndex}.resultado`}
                              value="1"
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="1"
                                control={
                                  <Radio
                                    checked={
                                      values.opciones[sustanciaIndex]
                                        ?.resultado === "1"
                                    }
                                  />
                                }
                              />
                            </RadioGroup >
                          </TableCell >
                          <TableCell>
                            <RadioGroup
                              name={`opciones.${sustanciaIndex}.resultado`}
                              value="0"
                              onChange={(v) => {
                                handleChange(v);
                                setFieldValue(`opciones.${sustanciaIndex}.seleccionOpcion`, initialValues.seleccionOpcion);
                              }}
                            >
                              <FormControlLabel
                                value="0"
                                control={
                                  <Radio
                                    checked={
                                      values.opciones[sustanciaIndex]
                                        ?.resultado === "0"
                                    }
                                  />
                                }
                              />
                            </RadioGroup>
                          </TableCell>
                          <TableCell>
                            {sustancia.opciones?.length && (
                              <FormikSelect
                                disabled={
                                  values.opciones[sustanciaIndex]?.resultado ===
                                  "0"
                                }
                                name={`opciones.${sustanciaIndex}.seleccionOpcion`}
                                label="Construcción"
                                variant="standard"
                                fullWidth
                              >
                                {sustancia.opciones.map(({ id, nombre }) => (
                                  <MenuItem value={id} key={id}>
                                    {nombre}
                                  </MenuItem>
                                ))}
                              </FormikSelect>
                            )}
                          </TableCell>
                        </TableRow >
                      );
                    })}
                  </TableBody >
                </Table >
                <CustomStepper contador={contador} maxGrupos={totalGrupos} />
                <div className={classes.planillaTableButtonContainer}>
                  {contador === totalGrupos - 1 && fromRgrl && (
                    <RequeridoLabel text="Al enviar el formulario no podrá modificar sus respuestas" />
                  )}
                  <FormikActions
                    submit={
                      contador === totalGrupos - 1
                        ? "Enviar formulario"
                        : "Grupo siguiente"
                    }
                    handleCancel={
                      contador !== 0 ? () => handleGoBack(values) : null
                    }
                    cancel={contador !== 0 ? "Grupo anterior" : null}
                  />
                </div>
              </TableContainer >
            </FieldArray >
          </Form >
        )}
      </Formik >
    </>
  );
};

export default PlanillaTable;
