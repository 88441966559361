import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getResponsables,
  newResponsable,
} from "../../../../redux/actions/empleador";
import {
  saveResponsableForm,
} from "../../../../redux/actions/nte";
import DataLabel from "../../../commons/labels/DataLabel";
import FechaPresentacionLabel from "../../../commons/planillas/FechaPresentacionLabel";
import AddResponsable from "./forms/AddResponsable";
import ResponsablesForm from "./forms/ResponsablesForm";
const initialValues = {
  responsableDeDatos: "",
  responsableDeSeguridad: "",
};

const ResponsablesContainer = ({ nextForm }) => {
  const dispatch = useDispatch();

  const datosEstablecimiento = useSelector(
    (state) => state.nte.datosEstablecimiento
  );
  const responsablesFormRedux = useSelector(
    (state) => state.nte.postNte.responsablesForm
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [formModel, setFormModel] = useState(null);
  const [respDatosState, setRespDatos] = useState(false);

  const isLoading = !formModel || datosEstablecimiento.loading;

  useEffect(() => {
    dispatch(getResponsables());
    setFormModel({
      ...initialValues,
      responsableDeDatos:
        responsablesFormRedux?.responsableDeDatos ??
        initialValues.responsableDeDatos,
      responsableDeSeguridad:
        responsablesFormRedux?.responsableDeSeguridad ??
        initialValues.responsableDeSeguridad,
    });
  }, []);

  const handleCloseResponsableDialog = () => {
    setDialogOpen(false);
  };

  const handleAddResponsable = (values) => {
    dispatch(newResponsable(values)).then((resp) => {
      toast.success("Responsable agregado correctamente");
      dispatch(getResponsables()).then((res) => {
        let objAux = {
          id: res.value.data[res.value.data.length - 1].id,
          nombre: res.value.data[res.value.data.length - 1].nombre,
        };
        if (respDatosState) {
          setFormModel({
            ...formModel,
            responsableDeDatos: objAux.id,
          });
        } else {
          setFormModel({
            ...formModel,
            responsableDeSeguridad: objAux.id,
          });
        }
        setDialogOpen(false);
      });
    });
  };

  const handleOpenDialog = (respDatos) => {
    setRespDatos(respDatos);
    setDialogOpen(true);
  };

  const handleSubmitForm = (values) => {
    dispatch(saveResponsableForm(values));
    nextForm("ImportarNóminas");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DataLabel
          firstText={"MU: " + datosEstablecimiento.data?.matrizUnica}
          secondText={datosEstablecimiento.data?.nombre?.split("-")[0]}
          thirdText={datosEstablecimiento.data?.domicilio}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <FechaPresentacionLabel />
      </div>
      <div style={{ margin: 10 }}>
        <ResponsablesForm
          model={formModel}
          handleSubmit={handleSubmitForm}
          handleOpenDialog={handleOpenDialog}
          isLoading={isLoading}
        />
      </div>
      <AddResponsable
        open={dialogOpen}
        handleSubmit={handleAddResponsable}
        handleClose={handleCloseResponsableDialog}
      />
    </div>
  );
};

export default ResponsablesContainer;
