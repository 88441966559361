import * as Yup from "yup";

export const validacionPais = Yup.string().nullable()

export const validacionProvincia = Yup.string().nullable();

export const validacionLocalidad = Yup.string().nullable();

export const validacionCalle = Yup.string().nullable().max(50, "Máximo 50 caracteres");

export const validacionKm = Yup.boolean();

/**
 * 
 * @param {string} decimal Nombre del campo booleano que contiene si el es numero(integer) o km(decimal).
 * @returns Validacion yup general para calle
 */
export const validacionNumeroCalle = (decimal) => Yup.string()
    .nullable()
    .required("Campo obligatorio")
    .test("validacion-km", "El número ingresado es inválido", function (value) {
        const km = this.parent[decimal] || false;
        if (!km) {
            return value?.match(/^\d+$/);
        } else {
            return true;
        }
    })
    .test({
        name: "cantidadDigitosDecimales",
        message: "Máximo 2 dígitos después de la coma",
        test: function (value) {
            const km = this.parent[decimal] || false;
            if (km) {
                return value?.match(/^[0-9]+(,\d{1,2})?$/);
            } else {
                return true;
            }
        },
    })
    .test({
        name: "cantidadTotalDigitos",
        message: "Máximo 8 dígitos enteros",
        test: function (value) {
            const km = this.parent[decimal] || false;
            if (km) {
                return value?.split(",")[0]?.length <= 8;
            } else {
                return value?.length <= 8;
            }
        },
    })

export const validacionCodigoPostal = Yup.string().max(5, "Máximo 5 caracteres")
    .required("Campo obligatorio")
    .nullable();


export const validacionDepartamento = Yup.string().max(5, "Máximo 5 caracteres");
export const validacionPiso = Yup.string().max(5, "Máximo 5 caracteres");

