import * as Yup from "yup";

export const validatePlanillaTable = Yup.object({
    opciones: Yup.array().of(
        Yup.object().shape({
            resultado: Yup.string().oneOf(["1", "0"]),
        }),

    ),
});
