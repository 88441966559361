import {
    Button,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { downloadInformeSiniestralidad } from "../../../redux/actions/denuncia";
import { downloadFile } from "../../../util/files";

const useStyles = makeStyles((theme) => ({

    menu: {
        marginTop: 45,
        marginLeft: 0,
    },
    buttonContainer: {
        display: "flex",
        margin: "10px",
    }

}));

const ExportSinietralidadButton = ({ disabled }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openMenu, setOpenMenu] = useState(null);

    const handleClose = (event) => {
        setOpenMenu(null);
    };

    const handleClickMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleClickDownloadFile = (type) => {
        dispatch(downloadInformeSiniestralidad(type))
            .then((res) => {
                downloadFile(res.value.data, res.value.fileName);
            })
            .catch((err) => {
                toast.error(err);

            });
    }

    return (
        <>
            <div className={classes.buttonContainer}>
                <Tooltip title="Exportar" arrow>
                    {/* la etiqueta de <span> es necesaria segun Material-UI porque
            sino el tooltip no se mostraria cuando el IconButton esta disabled */}
                    <span>
                        <Button
                            size="medium"
                            color="primary"
                            variant="contained"
                            onClick={handleClickMenu}
                            disabled={disabled}
                        >
                            <Typography>
                                Siniestralidad
                            </Typography>
                        </Button>
                    </span>
                </Tooltip>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={openMenu}
                keepMounted
                open={Boolean(openMenu)}
                onClose={handleClose}
                className={classes.menu}
            >
                <MenuItem onClick={() => handleClickDownloadFile("csv")}>
                    <ListItemText primary="Descargar CSV" />
                </MenuItem>
                <MenuItem onClick={() => handleClickDownloadFile("xlsx")}>
                    <ListItemText primary="Descargar XLSX" />
                </MenuItem>
                <MenuItem onClick={() => handleClickDownloadFile("xls")} >
                    <ListItemText primary="Descargar XLS" />
                </MenuItem>
            </Menu>
        </>
    );
};

export default ExportSinietralidadButton;
