import { Paper, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearRgrl, sendAllRGRLForm } from "../../../redux/actions/rgrl";
import Breadcrumb from "../../commons/breadcrumb/Breadcrumb";
import HeaderForm from "../../commons/labels/HeaderForm";
import ContratistasContainer from "./contratistas/ContratistasContainer";
import DecretoContainer from "./decretoContainer/DecretoContainer";
import EstablecimientoContainer from "./establecimientoContainer/EstablecimientoContainer";
import GremialistasContainer from "./gremialistas/GremialistasContainer";
import PlanillaAContainer from "./planillaAContainer/PlanillaAContainer";
import PlanillaCContainer from "./planillaCContainer/PlanillaCContainer";
import PresentacionContainer from "./prePresentacion/PrePresentacionContainer";
import PreguntaContainer from "./preguntaContainer/PreguntaContainer";
import ResponsablesContainerRGRL from "./responsables/ResponsablesContainer";

const useStyles = makeStyles({
  breadcrumbContainer: {
    display: "flex",
    margin: 15,
  },
});

const RGRLContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const RGRLReduxState = useSelector((state) => state.rgrl);

  const [form, setForm] = useState("Establecimiento");
  const [fromBread, setFromBread] = useState(false);
  const [breadcrumbFields, setBreadcrumbFields] = useState([form]);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);



  const handleSubmitLastForm = async () => {
    const resultadosRGRL = [
      // Aplanar preguntas decreto
      ...RGRLReduxState.postRgrl.preguntasDecreto.preguntas
        .flat()
        .map((a) => a.itemsFormularioArt)
        .flat(),
      ...RGRLReduxState.postRgrl.planillaA,
      ...RGRLReduxState.postRgrl.planillaC,
    ];
    const gremialistas = [];
    const responsables = [];

    RGRLReduxState.postRgrl.gremialistas.forEach((grem) => {
      gremialistas.push(grem.id);
    });
    RGRLReduxState.postRgrl.responsables.forEach((resp) => {
      responsables.push(resp.idResponsableEmpleador);
    });

    // para poder hacer las validaciones en Yup, estuvimos
    // trabajando con el valor como string, ahora es necesario
    // enviarlo al back como float (con punto ".") ya que en el
    // back se usa el punto como separador decimal (no hay i18n)
    const superficieAsFloat = Number.parseFloat(
      RGRLReduxState.postRgrl.decretoForm.superficie.replace(",", ".")
    );

    const aux = {
      idDependenciaEmpleador: RGRLReduxState.searchedRgrl,
      idDecreto: RGRLReduxState.postRgrl.decretoForm.id,
      superficie: superficieAsFloat,
      totalEstablecimientos:
        RGRLReduxState.postRgrl.decretoForm.totalEstablecimientos,
      observaciones: RGRLReduxState.postRgrl.decretoForm.observaciones,
      cantidadTrabajadoresEstablecimiento:
        RGRLReduxState.postRgrl.decretoForm.totalTrabajadoresEstablecimientos,
      resultadosRGRL: resultadosRGRL,
      gremialistas: gremialistas,
      contratistas: RGRLReduxState.postRgrl.contratistas,
      responsablesEmpleador: responsables,
    };

    setIsDownloadingPdf(true);

    await dispatch(sendAllRGRLForm(aux))
      .then((res) => {
        toast.success("RGRL enviada correctamente");
      })
      .catch((err) => {
        toast.error(err);
        setIsDownloadingPdf(false);
      });

    setIsDownloadingPdf(false);
    // Se deben capturar los valores antes de que el objeto redux quede en INITIAL_STATE
    const establecimiento = JSON.parse(
      JSON.stringify(RGRLReduxState.datosEstablecimiento.data)
    );
    const idDependencia = RGRLReduxState.searchedRgrl;
    // La limpieza del objeto redux se debe realizar antes de la navegacion
    // porque no se pueden ejecutar acciones para un componente desmontado.
    dispatch(clearRgrl());
    // Una vez que el usuario termina de cargar el RGRL, deberia
    // ser llevado a establecimientos, para que pueda ver el estado
    // del formulario enviado.
    navigate(`/prevencion/establecimientos/${establecimiento.matrizUnica}`, {
      state: {
        ...establecimiento,
        // DetalleEstablecimiento.js necesita el idDependencia
        idDependenciaEmpleador: idDependencia,
      },
    });
  };

  const handleSetForm = (form) => {
    if (!breadcrumbFields.includes(form)) {
      const auxArray = [...breadcrumbFields, form];
      setBreadcrumbFields(auxArray);
    }
    setForm(form);
  };

  return (
    <Paper
      style={{ overflow: "hidden", height: "fit-content", minHeight: 650 }}
    >
      <HeaderForm title="RGRL" />
      <div className={classes.breadcrumbContainer}>
        <Breadcrumb
          setForm={setForm}
          setFields={setBreadcrumbFields}
          fields={breadcrumbFields}
          setFromBread={() => setFromBread(true)}
          form={form}
        />
      </div>
      <div style={{ margin: 20 }}>
        {
          {
            Establecimiento: (
              <EstablecimientoContainer
                nextForm={handleSetForm}
                fromBread={fromBread}
              />
            ),
            Decreto: <DecretoContainer nextForm={handleSetForm} />,
            PreguntasDelDecreto: <PreguntaContainer nextForm={handleSetForm} />,
            PlanillaA: <PlanillaAContainer nextForm={handleSetForm} />,
            PlanillaC: <PlanillaCContainer nextForm={handleSetForm} />,
            Gremialistas: <GremialistasContainer nextForm={handleSetForm} />,
            Contratistas: <ContratistasContainer nextForm={handleSetForm} />,
            Responsables: (
              <ResponsablesContainerRGRL handleCancel={() => {
                clearRgrl();
                setBreadcrumbFields(["Establecimiento"]);
                setForm("Establecimiento");
              }}
                nextForm={handleSetForm}
              />
            ),
            Presentación: (
              <PresentacionContainer
                handleSubmitForm={handleSubmitLastForm}
                isDownloadingPdf={isDownloadingPdf}
              />
            ),
          }[form]
        }
      </div>
    </Paper>
  );
};

export default RGRLContainer;
