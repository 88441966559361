import axios from "axios";

export const SAVE_REPONSABLES_FORM = "SAVE_RESPONSABLES_FORM";
export const CLEAR_NTE = "CLEAR_NTE";
export const CLEAR_POST_NTE = "CLEAR_POST_NTE";
export const POST_ALL_NTE = "POST_ALL_NTE";
export const CLEAR_SEARCHED_NTE = "CLEAR_SEARCHED_NTE";
export const POST_SEARCHED_NTE = "POST_SEARCHED_NTE";
export const GET_DATOS_ESTABLECIMIENTOS = "GET_DATOS_ESTABLECIMIENTOS";
export const VALIDAR_DISPONIBILIDAD_NTE = "VALIDAR_DISPONIBILIDAD_NTE";
export const DESCARGAR_ESTADO_NOMINA = "DESCARGAR_ESTADO_NOMINA";
export const DESCARGAR_ESTRUCTURA_ARCHIVO = "DESCARGAR_ESTRUCTURA_ARCHIVO";
export const SAVE_IMPORTACION_ID_RAR = "SAVE_IMPORTACION_ID_RAR";
export const ENVIAR_NTE_FIRMADO = "ENVIAR_NTE_FIRMADO";
export const IMPORTAR_CSV_RAR_PENDING = "IMPORTAR_CSV_RAR_PENDING";
export const IMPORTAR_CSV_RAR_FULFILLED = "IMPORTAR_CSV_RAR_FULLFILED";
export const IMPORTAR_CSV_RAR_REJECTED = "IMPORTAR_CSV_RAR_REJECTED";
export const importarRar = (values) => (dispatch) => {
  dispatch({ type: IMPORTAR_CSV_RAR_PENDING });

  return axios
    .post("rar/importarRAR", values)
    .then((response) => {
      dispatch({
        type: IMPORTAR_CSV_RAR_FULFILLED,
        payload: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: IMPORTAR_CSV_RAR_REJECTED,
        error: error,
      });
      return Promise.reject(error);
    });
};

export const downloadEstructuraArchivo = () => (dispatch) => {
  return dispatch({
    type: DESCARGAR_ESTRUCTURA_ARCHIVO,
    payload: axios
      .get(`rar/visualizarInfoEstructuraArchivo`)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const downloadEstadoNomina = (establecimiento) => (dispatch) => {
  return dispatch({
    type: DESCARGAR_ESTADO_NOMINA,
    payload: axios
      .get(
        `rar/descargarArchivoImportacion?idDependenciaEmpleador=${establecimiento}`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        let fileName = response?.headers['content-disposition']?.split("filename=")[1];
        return { data: response.data, fileName }
      })
      .catch((error) => Promise.reject(error)),
  });
};
export const validarDisponibilidadDeCarga = (establecimiento) => (dispatch) => {
  return dispatch({
    type: VALIDAR_DISPONIBILIDAD_NTE,
    payload: axios
      .get(
        `rar/validarDisponibilidadCargaRAR?idDependenciaEmpleador=${establecimiento}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const getDatosEstablecimiento = (establecimiento) => (dispatch) => {
  return dispatch({
    type: GET_DATOS_ESTABLECIMIENTOS,
    payload: axios
      .get(
        `dependenciaEmpleador/datosBasicos?idDependenciaEmpleador=${establecimiento}`
      )
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const postAllNte = (values) => (dispatch) => {
  return dispatch({
    type: POST_ALL_NTE,
    payload: axios
      .post("", values)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const saveResponsableForm = (values) => (dispatch) => {
  return dispatch({
    type: SAVE_REPONSABLES_FORM,
    payload: values,
  });
};

export const saveImportacionIdRar = (id) => (dispatch) => {
  return dispatch({
    type: SAVE_IMPORTACION_ID_RAR,
    payload: id,
  });
};

export const enviarNteFirmado = (values) => (dispatch) => {
  return dispatch({
    type: ENVIAR_NTE_FIRMADO,
    payload: axios
      .post("rar/cargarAdjuntosRar", values)
      .then((response) => ({ data: response.data }))
      .catch((error) => Promise.reject(error)),
  });
};

export const clearNte = () => (dispatch) => {
  return dispatch({
    type: CLEAR_NTE,
  });
};

export const clearPostNte = () => (dispatch) => {
  return dispatch({
    type: CLEAR_POST_NTE,
  });
};

export const clearSearchedNte = () => (dispatch) => {
  return dispatch({
    type: CLEAR_SEARCHED_NTE,
  });
};

export const postSearchedNte = (establecimiento) => (dispatch) => {
  return dispatch({
    type: POST_SEARCHED_NTE,
    payload: establecimiento,
  });
};
