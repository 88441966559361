import { FormControlLabel, Grid, Radio, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import FormikActions from "../../../commons/formik/FormikActions";
import FormikRadio from "../../../commons/formik/FormikRadio";
import FormikTextField from "../../../commons/formik/FormikTextField";
import { useStyles } from "../styles/formStyle";
import { caracteristicasFormValidation } from "../validation/formValidation";
import TipoContratista from "./TipoContratista";

const IdentificacionForm = ({ initialValues, handleSubmit, formModel }) => {
  const classes = useStyles();
  const tiposContratista = useSelector((state) => state.obra.tipoContratista);

  const CONTRATISTA_DEFAULT_35 = tiposContratista.data.find(
    (e) => e.nombre == "Contratista Principal"
  );

  return (
    <Formik
      initialValues={formModel}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={caracteristicasFormValidation}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6">Característica de la Obra</Typography>
            </Grid>

            <Grid item xs={3} className={classes.paddingContainer}>
              <div className={classes.radioContainer}>
                <FormikRadio name="resolucion">
                  <FormControlLabel
                    value="5197"
                    control={<Radio />}
                    label="Resolución 51/97"
                    labelPlacement="end"
                    onChange={() =>
                      setFieldValue(
                        "idTipoContratista",
                        initialValues.idTipoContratista
                      )
                    }
                  />

                  <div className={classes.formikRadio}>
                    <FormControlLabel
                      value="3598"
                      control={<Radio />}
                      label="Resolución 35/98"
                      labelPlacement="end"
                      onChange={() =>
                        setFieldValue(
                          "idTipoContratista",
                          CONTRATISTA_DEFAULT_35.id
                        )
                      }
                    />
                  </div>
                  <div className={classes.formikRadio}>
                    <FormControlLabel
                      value="319/99"
                      control={<Radio />}
                      label="Resolución 319/99"
                      labelPlacement="end"
                      onChange={() =>
                        setFieldValue(
                          "idTipoContratista",
                          initialValues.idTipoContratista
                        )
                      }
                    />
                  </div>
                </FormikRadio>
              </div>
            </Grid>

            <Grid item xs={3}>
              <TipoContratista
                opcionesContratista={tiposContratista.data}
                values={values}
              />
            </Grid>

            <Grid
              container
              item
              xs={6}
              className={classes.paddingContainer}
              direction="column"
            >
              <div className={classes.formikInput}>
                <FormikTextField
                  name="superficieAConstruir"
                  label="Superficie a Construir*"
                  fullWidth
                />
              </div>
              <div className={classes.formikInput}>
                <FormikTextField
                  name="numeroPlantas"
                  label="Nro. de Plantas*"
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} className={classes.paddingContainer}>
              <div className={classes.containerMultiline}>
                <FormikTextField
                  name="observaciones"
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  multiline
                  maxRows={15}
                  minRows={2}
                />
                {values.observaciones.length > 0 && (
                  <Typography variant="caption">
                    {values.observaciones.length} caracteres
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <FormikActions
              submit="Siguiente"
              disabled={!(values.resolucion && values.idTipoContratista)}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default IdentificacionForm;
