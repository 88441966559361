import { compareAsc } from "date-fns";

export function isDecimal(value) {
  if (value?.includes(",")) {
    return value?.match(/^[0-9]+(,\d{1,2})?$/);
  } else {
    return true;
  }
}

export function dateIsBefore(fechaMenor, fechaMayor) {
  // Compare the two dates and return
  // 1 if the first date is after the second,
  // -1 if the first date is before the second
  // or 0 if dates are equal.
  // Remueve horas de las fechas para hacer una comparacion correcta
  return (
    compareAsc(
      fechaMenor.setHours(0, 0, 0, 0),
      fechaMayor.setHours(0, 0, 0, 0)
    ) <= 0
  );
}
