import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getCalle,
  getCodigoPostal,
  getLocalidades,
  getProvincias,
} from "../../../../../redux/actions/direccion";
import { getActividadesEmpleador } from "../../../../../redux/actions/obra";
import FormikActions from "../../../../commons/formik/FormikActions";
import FormikAutocomplete from "../../../../commons/formik/FormikAutocomplete";
import FormikCheckbox from "../../../../commons/formik/FormikCheckbox";
import FormikDatePicker from "../../../../commons/formik/FormikDatePicker";
import FormikSelect from "../../../../commons/formik/FormikSelect";
import FormikTextField from "../../../../commons/formik/FormikTextField";
import CircularLoading from "../../../../commons/loading/CircularLoading";
import { validateNewEstablecimiento } from "../../validation/formValidation";

const initialValues = {
  nombre: "",
  cuit: "",
  esTercero: false,
  fechaInicioActividad: null,
  actividad: "",
  pais: "",
  provincia: "",
  localidad: "",
  calle: "",
  numero: "",
  km: false,
  piso: "",
  departamento: "",
  codigoPostal: "",
  entreCalle: "",
  entreCalle1: "",
  latitud: "",
  longitud: "",
};
const listaPais = [{ id: "1", nombre: "Argentina" }];

const NewEstablecimientoDialog = ({ open, handleClose, handleSumbit }) => {
  const dispatch = useDispatch();
  const calles = useSelector((state) => state.direccion.calle);
  const provincias = useSelector((state) => state.direccion.provincias);
  const localidades = useSelector((state) => state.direccion.localidades);
  const actividades = useSelector((state) => state.obra.actividadesEmpleador);
  const postDependenciaEmpleador = useSelector(
    (state) => state.obra.postDependenciaEmpleador
  );
  const [provinciaIsCapital, setProvinciaIsCapital] = useState(false);
  const [callesList, setCallesList] = useState([]);

  const loading = postDependenciaEmpleador.loading;

  useEffect(() => {
    dispatch(getProvincias());
    dispatch(getActividadesEmpleador());
  }, [dispatch]);

  useEffect(() => {
    if (calles.data) {
      setCallesList(calles.data);
    }
  }, [calles.data]);

  const handleChangeProvincia = (value, setFieldValue) => {
    dispatch(getLocalidades(value.id));
  };

  const handleGetCodigoPostal = (
    provincia,
    localidad,
    numero,
    calle,
    setFieldValue
  ) => {
    dispatch(
      getCodigoPostal(provincia, localidad, parseInt(numero), calle)
    ).then((res) => {
      if (res.value.data.codigoPostal) {
        setFieldValue("codigoPostal", res.value.data.codigoPostal);
      }
    });
  };

  const handleLocalidadesChange = (value) => {
    dispatch(getCalle(value, value));
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle>Nuevo Establecimiento de Obra</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleSumbit(values)}
        validationSchema={validateNewEstablecimiento}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <DialogContent>
              {loading ? (
                <CircularLoading />
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <FormikTextField
                      name="nombre"
                      label="Nombre del Establecimiento *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormikCheckbox name="esTercero" label="Es de Terceros" />
                  </Grid>
                  <Grid item xs={2}>
                    <FormikTextField
                      disabled={!values.esTercero}
                      name="cuit"
                      label="CUIT"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormikDatePicker
                      name="fechaInicioActividad"
                      label="Inicio de Actividad *"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikAutocomplete
                      name="actividad"
                      label="Actividad de Revisión 4*"
                      options={actividades.data}
                      renderOption={(option) => option.nombre}
                      filterOptions={(option) => option.nombre}
                      fullWidth
                      getOptions={() => dispatch(getActividadesEmpleador())}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormikSelect
                      name="pais"
                      label={`País *`}
                      variant="standard"
                      fullWidth
                    >
                      {listaPais.map(({ id, nombre }) => (
                        <MenuItem value={id} key={id}>
                          {nombre}
                        </MenuItem>
                      ))}
                    </FormikSelect>
                  </Grid>
                  <Grid item xs={4}>
                    <FormikAutocomplete
                      name="provincia"
                      label="Provincia *"
                      options={provincias.data}
                      renderOption={(option) => option.nombre}
                      filterOptions={(option) => option.nombre}
                      fullWidth
                      callBack={(value) => {
                        if (value) {
                          handleChangeProvincia(value);
                          if (value.id === "Capital Federal") {
                            handleLocalidadesChange(value.id);
                            setProvinciaIsCapital(true);
                          } else {
                            setProvinciaIsCapital(false);
                          }
                        }
                        setFieldValue("localidad", "");
                        setFieldValue("calle", "");
                        setFieldValue("numero", "");
                        setFieldValue("codigoPostal", "");
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormikAutocomplete
                      name="localidad"
                      label="Localidad *"
                      options={localidades.data}
                      disabled={!values.provincia}
                      renderOption={(option) => option.nombre}
                      filterOptions={(option) => option.nombre}
                      fullWidth
                      callBack={(value) => {
                        if (!value) {
                          setFieldValue("calle", "");
                          setFieldValue("numero", "");
                          setFieldValue("codigoPostal", "");
                        }
                      }}
                      getOptions={() =>
                        dispatch(getLocalidades(values["provincia"]))
                      }
                    />
                  </Grid>

                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={5}>
                      {!provinciaIsCapital ? (
                        <FormikTextField
                          name="calle"
                          label="Calle *"
                          fullWidth
                          disabled={!values.localidad}
                          onBlur={(value) => {
                            if (!value.target.value) {
                              setFieldValue("numero", "");
                              setFieldValue("codigoPostal", "");
                            }
                          }}
                        />
                      ) : (
                        <FormikAutocomplete
                          name="calle"
                          label="Calle *"
                          options={callesList}
                          fullWidth
                          disabled={!values.localidad}
                          callBack={(value) => {
                            if (!value) {
                              setFieldValue("numero", "");
                              setFieldValue("codigoPostal", "");
                            }
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <FormikTextField
                        name="numero"
                        label="Número"
                        fullWidth
                        disabled={!values.calle}
                        onBlur={(value) => {
                          if (value.target.value) {
                            handleGetCodigoPostal(
                              values.provincia,
                              values.localidad,
                              values.numero,
                              values.calle,
                              setFieldValue
                            );
                          } else {
                            setFieldValue("codigoPostal", "");
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <FormikCheckbox name="km" label="KM" />
                    </Grid>

                    <Grid item xs={2}>
                      <FormikTextField name="piso" label="Piso" fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                      <FormikTextField
                        name="departamento"
                        label="Dpto"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={4}>
                      <FormikTextField
                        name="codigoPostal"
                        label="Código Postal *"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        name="entreCalle"
                        label="Entre Calle"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikTextField
                        name="entreCalle1"
                        label="Y Calle"
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={6}>
                      <FormikTextField
                        name="latitud"
                        label="Latitud"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikTextField
                        name="longitud"
                        label="Longitud"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <FormikActions disabled={loading} handleCancel={handleClose} />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default NewEstablecimientoDialog;
