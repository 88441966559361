import {
  Button,
  TablePagination,
  Typography,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";

const columns = [
  { id: "cuit", label: "CUIT" },
  { id: "nombreCompleto", label: "Apellido y Nombre" },
  { id: "cargoEmpresa", label: "Cargo" },
  { id: "nroMatricula", label: "Matrícula" },
  { id: "titulo", label: "Título" },
  { id: "matriculaOtorgadaPor", label: "Otorgado Por" },
  { id: "propio", label: "Propio" },
  { id: "actions", label: "Acciones" },
];

const useStyles = makeStyles((theme) => ({
  icon: {
    marginLeft: 10,
  },
  tableFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainer: {
    margin: "30px 25px 0px 0px",
    "& button": {
      marginLeft: 10,
    },
  },
  tableCell: {
    overflowWrap: "break-word",
  },
  messageContainer: {
    minHeight: 150,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ResponsablesTable = ({
  data,
  setOpenAddResponsableDialog,
  deleteResponsable,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dataWithElements = data.length ? true : false;

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenAdd = () => {
    setOpenAddResponsableDialog(true)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell align="center" key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {dataWithElements && (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : data
              ).map((row) => (
                <TableRow key={row.cuit}>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.cuit}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.nombreCompleto}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.cargoEmpresa}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.nroMatricula}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.titulo}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.matriculaOtorgadaPor}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.propio ? "Sí" : "No"}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Tooltip title="Eliminar" placement="bottom" arrow>
                      <DeleteIcon
                        onClick={() => deleteResponsable(row)}
                        className={classes.icon}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {!dataWithElements && (
          <div className={classes.messageContainer}>
            <Typography>No hay responsables agregados</Typography>
          </div>
        )}
        <div className={classes.tableFooter}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : to}`
            }
          />
          <div className={classes.buttonContainer}>
            {
              <>
                <Button
                  color="primary"
                  onClick={handleOpenAdd}
                >
                  Agregar
                </Button>
              </>
            }
          </div>
        </div>
      </TableContainer>
    </>
  );
};

export default ResponsablesTable;
